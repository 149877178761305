import { trapFocus, removeTrapFocus } from '@/lib/a11y'

/**
 * CartDrawer class represents a custom web component that extends HTMLElement.
 * It provides functionality for opening and closing a cart drawer.
 */
class CartDrawer extends window.HTMLElement {
  constructor() {
    super()

    // Set up event listeners and accessibility for cart drawer.
    this.addEventListener(
      'keyup',
      (evt) => evt.code === 'Escape' && this.close()
    )
    this.querySelector('#CartDrawer-Overlay').addEventListener(
      'click',
      this.close.bind(this)
    )
    this.setHeaderCartIconAccessibility()
  }

  /**
   * Sets accessibility attributes and event listeners for the header cart icon.
   */
  setHeaderCartIconAccessibility() {
    const cartLink = document.querySelector('#cart-icon-bubble')
    cartLink.setAttribute('role', 'button')
    cartLink.setAttribute('aria-haspopup', 'dialog')
    cartLink.addEventListener('click', (event) => {
      event.preventDefault()
      this.open(cartLink)
    })
    cartLink.addEventListener('keydown', (event) => {
      if (event.code.toUpperCase() === 'SPACE') {
        event.preventDefault()
        this.open(cartLink)
      }
    })
  }

  /**
   * Opens the cart drawer and manages focus and styling.
   * @param {HTMLElement} triggeredBy - The element that triggered the opening.
   */
  open(triggeredBy) {
    if (triggeredBy) this.setActiveElement(triggeredBy)

    // Use a timeout to ensure the animation is triggered correctly.
    setTimeout(() => {
      this.classList.add('active')
    })

    this.addEventListener(
      'transitionend',
      () => {
        const containerToTrapFocusOn = document.getElementById('CartDrawer')
        const focusElement = this.querySelector('[tabindex="-1"]')
        trapFocus(containerToTrapFocusOn, focusElement)

        // Disable overflow scroll on body.
        document.body.classList.add('overflow-hidden')
      },
      { once: true }
    )

    document.body.classList.add('overflow-hidden')
  }

  /**
   * Closes the cart drawer and resets focus and styling.
   */
  close() {
    this.classList.remove('active')
    removeTrapFocus(this.activeElement)
    document.body.classList.remove('overflow-hidden')
  }

  /**
   * Renders the contents of the cart drawer using parsed state data.
   * @param {Object} parsedState - The parsed state data.
   */
  renderContents(parsedState) {
    this.productId = parsedState.id

    // Render sections based on parsed state data.
    this.getSectionsToRender().forEach((section) => {
      const sectionElement = section.selector
        ? document.querySelector(section.selector)
        : document.getElementById(section.id)

      sectionElement.innerHTML = this.getSectionInnerHTML(
        parsedState.sections[section.id],
        section.selector
      )
    })

    // Set up the overlay click event and open the drawer with a timeout.
    setTimeout(() => {
      this.querySelector('#CartDrawer-Overlay').addEventListener(
        'click',
        this.close.bind(this)
      )
      this.open()
    })
  }

  /**
   * Gets the inner HTML of a section from provided HTML content.
   * @param {string} html - The HTML content to parse.
   * @param {string} selector - The selector to target within the parsed HTML.
   * @returns {string} - The inner HTML of the selected section.
   */
  getSectionInnerHTML(html, selector = '.shopify-section') {
    return new window.DOMParser()
      .parseFromString(html, 'text/html')
      .querySelector(selector).innerHTML
  }

  /**
   * Returns an array of sections to render with their associated selectors/ids.
   * @returns {Array} - An array of objects representing sections to render.
   */
  getSectionsToRender() {
    return [
      {
        id: 'cart-drawer',
        selector: '#CartDrawer',
      },
      {
        id: 'cart-icon-bubble',
      },
    ]
  }

  /**
   * Sets the active element for focus management.
   * @param {HTMLElement} element - The element to set as active.
   */
  setActiveElement(element) {
    this.activeElement = element
  }
}

// Define the 'cart-drawer' custom element using the CartDrawer class.
window.customElements.define('cart-drawer', CartDrawer)
