/**
 * CartRemoveButton class represents a custom web component that extends HTMLElement.
 * It provides functionality to remove an item from the cart.
 */
class CartRemoveButton extends window.HTMLElement {
  constructor() {
    super()

    // Add a click event listener to handle item removal.
    this.addEventListener('click', (event) => {
      event.preventDefault()

      // Find the closest cart element (either cart-items or cart-drawer-items).
      const cartItems =
        this.closest('cart-items') || this.closest('cart-drawer-items')

      // Call the updateQuantity method of the closest cart element with quantity 0.
      if (cartItems) {
        cartItems.updateQuantity(this.dataset.index, 0)
      }
    })
  }
}

// Define the 'cart-remove-button' custom element using the CartRemoveButton class.
window.customElements.define('cart-remove-button', CartRemoveButton)
