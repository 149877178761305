class CountdownTimer extends window.HTMLElement {
  constructor() {
    super()

    // Initialize intervals
    this.interval = null
    this.intervalUntilMidnight = null
    this.now = null

    // Initialize countdown ui elements
    this.daysSpan = this.querySelector('[data-days]')
    this.hoursSpan = this.querySelector('[data-hours]')
    this.minutesSpan = this.querySelector('[data-minutes]')
    this.secondsSpan = this.querySelector('[data-seconds]')
  }

  get targetDate() {
    // Get the target date and time from the element attributes.
    const date = this.getAttribute('data-date')
    const time = this.getAttribute('data-format') || '00:00'
    const [hours, minutes] = time.split(':')
    const [year, month, day] = date.split('-').map((num) => parseInt(num, 10))
    return new Date(year, month - 1, day, hours, minutes)
  }

  get storeTime() {
    // Get the store date and time from the data-store-time attributes.
    const storeTimeStr = this.getAttribute('data-store-time')
    return new Date(storeTimeStr)
  }

  displayCountdownValues(days, hours, minutes, seconds) {
    // Update the countdown values using style property as per your HTML structure
    this.daysSpan.style.setProperty('--value', days)
    this.hoursSpan.style.setProperty('--value', hours)
    this.minutesSpan.style.setProperty('--value', minutes)
    this.secondsSpan.style.setProperty('--value', seconds)
  }

  calculateTimeDifference(timestamp) {
    // calculate days, hours, minutes, and seconds.
    const days = Math.floor(timestamp / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timestamp % (1000 * 60)) / 1000)

    return { days, hours, minutes, seconds }
  }

  clearAllIntervals() {
    // clear countdown interval until target date
    clearInterval(this.interval)

    // clear countdown interval until midnight
    clearInterval(this.intervalUntilMidnight)

    // clear interval for updating store time now
    clearInterval(this.now)
  }

  displayCountdownTimer(time) {
    if (time <= 0) {
      // reset countdown
      this.displayCountdownValues(0, 0, 0, 0)

      this.clearAllIntervals()
    } else {
      // Get the calculated time difference
      const { days, hours, minutes, seconds } =
        this.calculateTimeDifference(time)

      // Display the result in the countdown ui elements.
      this.displayCountdownValues(days, hours, minutes, seconds)
    }
  }

  countdownUntilMidnight() {
    // Get the current date and time
    const currentDate = new Date()

    // Calculate the time until midnight
    const midnight = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1, // Next day
      0, // Midnight hour
      0, // Midnight minute
      0 // Midnight second
    )

    // Calculate the difference between now and midnight
    const difference = midnight - currentDate

    // Display the countdown timer
    this.displayCountdownTimer(difference)
  }

  updateCountdown() {
    // Find the difference between now and the target date and time.
    const endDate = new Date(this.targetDate)
    const difference = endDate - this.now

    // Display the countdown timer
    this.displayCountdownTimer(difference)
  }

  connectedCallback() {
    // store the current store time
    this.now = this.storeTime

    // Function to update 'now' every second
    const updateNow = () => {
      this.now = new Date()
    }

    // Call the 'updateNow' function initially
    updateNow()

    // Update 'now' every second
    this.now = setInterval(updateNow, 1000)

    if (!isNaN(new Date(this.targetDate).getTime())) {
      // check if target date is valid and update countdown every second
      this.interval = setInterval(() => this.updateCountdown(), 1000)
    } else {
      // update countdown every second until midnight (if target date is invalid)
      this.intervalUntilMidnight = setInterval(
        () => this.countdownUntilMidnight(),
        1000
      )
    }
  }

  disconnectedCallback() {
    this.clearAllIntervals()
  }
}

// Define the custom element.
window.customElements.define('core-countdown-timer', CountdownTimer)
