class DebugPanelToggle extends HTMLElement {
  constructor() {
    super()
    // Binding the drag and endDrag methods to the class instance
    this.boundDrag = this.drag.bind(this)
    this.boundEndDrag = this.endDrag.bind(this)
  }

  clamp(value, min, max) {
    return Math.min(Math.max(value, min), max)
  }

  drag(event) {
    // Set the --debug-panel-position custom property to the x position of the drag event
    const relativeBottomY = this.clamp(
      100 - ((event.clientY + 50) / window.innerHeight) * 100,
      5,
      90
    )

    this.value = relativeBottomY

    this.style.setProperty('--debug-panel-position', `${relativeBottomY}%`)
    localStorage.setItem('debugPosition', `${relativeBottomY}%`)

    // Listen for pointer move and up events on the entire document to ensure smooth dragging
    document.addEventListener('pointermove', this.boundDrag)
    document.addEventListener('pointerup', this.boundEndDrag)
  }

  endDrag(event) {
    event.preventDefault()
    // Remove the move and up listeners when dragging ends
    document.removeEventListener('pointermove', this.boundDrag)
    document.removeEventListener('pointerup', this.boundEndDrag)
  }

  connectedCallback() {
    this.dragItem = this.querySelector('[data-drag]')

    // Initialize dragging on pointer down
    this.dragItem.addEventListener('pointerdown', (event) => {
      // Prevent default action to avoid potential interference with drag behavior
      event.preventDefault()
      this.boundDrag(event)
    })

    const debugPosition = localStorage.getItem('debugPosition')
    if (debugPosition != null) {
      this.style.setProperty('--debug-panel-position', debugPosition)
    }
    this.classList.add('active')
  }

  disconnectedCallback() {
    // Ensure to clean up event listeners when the element is removed from the DOM
    this.dragItem.removeEventListener('pointerdown', this.boundDrag)
    document.removeEventListener('pointermove', this.boundDrag)
    document.removeEventListener('pointerup', this.boundEndDrag)
  }
}

customElements.define('debug-panel-toggle', DebugPanelToggle)
