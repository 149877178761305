/**
 * CartQtyUpsell class represents a custom web component that extends HTMLElement.
 * It provides functionality to update the quantity of an item in the cart.
 */
class CartQtyUpsell extends window.HTMLElement {
  constructor() {
    super()

    // Add a click event listener to handle quantity updates.
    this.addEventListener('click', (event) => {
      event.preventDefault()

      // Find the closest cart element (either cart-items or cart-drawer-items).
      const cartItems =
        this.closest('cart-items') || this.closest('cart-drawer-items')

      // Call the updateQuantity method of the closest cart element with dataset values.
      if (cartItems) {
        cartItems.updateQuantity(
          this.dataset.index,
          this.dataset.qty,
          'no-qty-element-update'
        )
      }
    })
  }
}

// Define the 'cart-qty-upsell' custom element using the CartQtyUpsell class.
window.customElements.define('cart-qty-upsell', CartQtyUpsell)
