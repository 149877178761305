class DebugNavbarController extends HTMLUListElement {
  constructor() {
    super()
    this.boundHandleButtonClick = this.handleButtonClick.bind(this)
  }

  handleButtonClick(event) {
    const button = event.currentTarget
    const action = button.getAttribute('aria-controls')
    window.console.log('action', button)
    if (action) {
      const target = document.getElementById(action)
      this.navBarItems.forEach((button) => {
        button.setAttribute('aria-expanded', false)
        button.classList.remove('active')
      })
      this.debugPanels.forEach((panel) => {
        panel.classList.remove('active')
      })
      button.setAttribute('aria-expanded', true)
      button.classList.add('active')
      target.classList.add('active')
    }
  }

  connectedCallback() {
    this.navBarItems = this.querySelectorAll('button')
    this.debugPanels = document.querySelectorAll('[data-debug-panel]')
    this.navBarItems.forEach((button) => {
      button.addEventListener('click', this.boundHandleButtonClick)
    })
  }

  disconnectedCallback() {
    this.navBarItems.forEach((button) => {
      button.removeEventListener('click', this.boundHandleButtonClick)
    })
  }
}

customElements.define('debug-navbar', DebugNavbarController, { extends: 'ul' })
