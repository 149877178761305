class HeaderWrapper extends window.HTMLElement {
  connectedCallback() {
    this.header = this
    this.preSpacer = document.querySelector('.pre-spacer')
    this.activeMenuTitle = ''
    this.stickyAnnouncementBar = document.querySelector('.sticky__ab')
    this.announcementPreSpacer = document.querySelector('.pre-spacer__ab')

    // check of data-sticky-header exists to init sticky header
    if (this.header.dataset?.stickyHeader !== undefined) {
      this.initSticky()
    }

    this.initDesktopMenuHoverState()
    this.onMenuLeave()
    this.predictiveSearchSetup()

    // set --height-header variable based off .shopify-section-group-header-group heights combined

    const headerGroup = document.querySelectorAll(
      '.shopify-section-group-header-group'
    )

    if (headerGroup) {
      let height = 0
      headerGroup.forEach((node) => {
        height += node.offsetHeight
      })
      document.documentElement.style.setProperty(
        '--height-header',
        `${height}px`
      )
    }
  }

  initSticky() {
    this.headerBounds = {}
    this.currentScrollTop = 0
    this.preventReveal = false

    this.onScrollHandler = this.onScroll.bind(this)
    this.hideHeaderOnScrollUp = () => {
      this.preventReveal = true
    }

    window.addEventListener('scroll', this.onScrollHandler, false)

    this.createObserver()
  }

  onMenuLeave() {
    // when mouse leaves menu, reset active menu title

    this.header.addEventListener('mouseleave', (e) => {
      this.resetMegaMenus(e)
    })

    this.header.querySelectorAll('.mega-menu').forEach((menu) => {
      menu.addEventListener('mouseleave', (e) => {
        this.resetMegaMenus(e)
      })
    })
  }

  onLinkHover(navLinks, link) {
    // add hover links to all navLinks
    navLinks.forEach((link) => {
      link.classList.add('nav-item-hovering')
      // set active menu title - lowercase
    })

    this.activeMenuTitle =
      link.querySelector('span')?.textContent.trim() || link.textContent.trim()

    // attempt to locate mega menu with active menu title
    const megaMenu = document.querySelector(
      `.mega-menu[data-menu-title="${this.activeMenuTitle}"]`
    )

    if (megaMenu) {
      // get parent details element

      // remove loading="lazy" from images

      megaMenu.querySelectorAll('img').forEach((img) => {
        img.removeAttribute('loading')
        // set fetchpriority to high
        img.setAttribute('fetchpriority', 'high')
      })

      this.resetMegaMenus()

      const detailsEl = megaMenu.closest('details')

      // open details element

      detailsEl.open = true

      // set aria-expanded to true

      detailsEl.setAttribute('aria-expanded', 'true')
    } else {
      this.resetMegaMenus()
    }
  }

  initDesktopMenuHoverState() {
    // attach to nav > a elements to detect hover state

    const navLinks = this.header.querySelectorAll('nav a.top-level-link')

    navLinks.forEach((link) => {
      link.addEventListener('mouseenter', () => {
        this.onLinkHover(navLinks, link)
      })

      link.addEventListener('mouseleave', () => {
        // remove hover links to all navLinks
        navLinks.forEach((link) => {
          link.classList.remove('nav-item-hovering')
        })
      })
    })
  }

  resetMegaMenus(e) {
    const megaMenus = document.querySelectorAll('.mega-menu-wrapper')

    this.activeMenuTitle = ''

    megaMenus.forEach((menu) => {
      if (menu.classList && menu.classList.contains('predictive-search')) {
        return
      }

      menu.open = false
      menu.setAttribute('aria-expanded', 'false')
    })
  }

  createObserver() {
    const observer = new window.IntersectionObserver((entries, observer) => {
      this.headerBounds = entries[0].intersectionRect
      observer.disconnect()
    })

    observer.observe(this.header)
  }

  predictiveSearchSetup() {
    this.predictiveSearchMobile = this.querySelector(
      '.predictive-search.mobile'
    )
    this.predictiveSearchDesktop = this.querySelector(
      '.predictive-search.desktop'
    )
    // listen to open event (details element)
    this.setupPredictiveSearchListeners(this.predictiveSearchMobile)
    this.setupPredictiveSearchListeners(this.predictiveSearchDesktop)
  }

  setupPredictiveSearchListeners(el) {
    el.addEventListener('toggle', (e) => {
      window.console.log('toggle', e.target.open)
      if (e.target.open) {
        this.header.parentElement.classList.add('search-open')

        // set aria-expanded to true
        el.setAttribute('aria-expanded', 'true')
        // lock scroll
        document.body.style.overflow = 'hidden'

        // focus on search input

        const searchInput = el.querySelector('.predictive-search-input')

        if (searchInput) {
          searchInput.focus()
        }
      } else {
        // set aria-expanded to false
        el.setAttribute('aria-expanded', 'false')

        this.header.parentElement.classList.remove('search-open')

        // unlock scroll
        document.body.style.overflow = ''
      }
    })
  }

  onScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (this.predictiveSearchMobile && this.predictiveSearchMobile.isOpen)
      return

    if (this.predictiveSearchDesktop && this.predictiveSearchDesktop.isOpen)
      return

    if (
      scrollTop > this.currentScrollTop &&
      scrollTop > this.headerBounds.bottom
    ) {
      if (this.preventHide) return
      window.requestAnimationFrame(this.hide.bind(this))
    } else if (
      scrollTop < this.currentScrollTop &&
      scrollTop > this.headerBounds.bottom
    ) {
      if (!this.preventReveal) {
        window.requestAnimationFrame(this.reveal.bind(this))
      } else {
        window.clearTimeout(this.isScrolling)

        this.isScrolling = setTimeout(() => {
          this.preventReveal = false
        }, 66)

        window.requestAnimationFrame(this.hide.bind(this))
      }
    } else if (scrollTop <= this.headerBounds.top) {
      if (this.stickyAnnouncementBar) {
        if (scrollTop === 0) {
          window.requestAnimationFrame(this.reset.bind(this))
        }
      } else {
        window.requestAnimationFrame(this.reset.bind(this))
      }
    }

    this.currentScrollTop = scrollTop
  }

  hide() {
    const classes = ['-translate-y-full', 'top-0', '!fixed', 'has-scrolled']
    this.preSpacer.style.height = `${this.header.parentElement.offsetHeight}px`
    this.header.parentElement.classList.add(...classes)

    if (this.stickyAnnouncementBar) {
      this.header.parentElement.style.top = `${0}px`

      this.stickyAnnouncementBar.classList.add(
        '-translate-y-full',
        'top-0',
        'has-scrolled'
      )
    }

    document.body.classList.add('header-hidden', 'has-scrolled')
  }

  reveal() {
    this.preSpacer.style.height = `${this.header.parentElement.offsetHeight}px`
    this.header.parentElement.classList.add('top-0', 'transition-transform')
    this.header.parentElement.classList.remove('-translate-y-full')

    if (this.stickyAnnouncementBar) {
      this.header.parentElement.style.top = `${this.stickyAnnouncementBar.offsetHeight}px`
      this.stickyAnnouncementBar.classList.add(
        'top-0',
        'transition-transform',
        '!fixed'
      )
      this.stickyAnnouncementBar.classList.remove(
        '-translate-y-full',
        'absolute'
      )
    }

    document.body.classList.remove('header-hidden')
  }

  reset() {
    const classes = [
      '-translate-y-full',
      'top-0',
      'transition-transform',
      '!fixed',
      'has-scrolled',
    ]
    this.preSpacer.style.height = '0'
    this.header.parentElement.classList.remove(...classes)

    if (this.stickyAnnouncementBar) {
      this.header.parentElement.style.top = `${0}px`
      this.header.parentElement.classList.remove('!fixed')

      this.stickyAnnouncementBar.classList.remove(...classes)
      this.stickyAnnouncementBar.classList.add('absolute', 'top-0')
    }
    document.body.classList.remove('has-scrolled')
  }
}

window.customElements.define('header-wrapper', HeaderWrapper)
