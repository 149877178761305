/**
 * Dynamically adds event listeners for specified event names and tracks them using Google Analytics.
 * @param {string[]} eventNames - An array of event names to listen for.
 */
function addEventListenersAndTrack(eventNames) {
  eventNames.forEach((eventName) => {
    document.addEventListener(eventName, () => {
      // Convert event name from 'status:cart:open' to 'status_cart_open' for tracking
      const formattedEventName = eventName.replace(/:/g, '_')

      if (window.gtag) {
        window.gtag('event', formattedEventName)
      }
    })
  })
}

addEventListenersAndTrack([
  'status:cart:add',
  'status:cart:open',
  'status:widget:open',
  'status:widget:close',
  'status:shopify:login',
  'status:user:login',
  'status:items:updated',
])
