class CartDrawerItems extends window.CartItems {
  /**
   * Returns an array of sections to render with their associated selectors/ids.
   * Overrides the parent method to provide customized section configuration.
   * @returns {Array} - An array of objects representing sections to render.
   */
  getSectionsToRender() {
    return [
      {
        id: 'CartDrawer',
        section: 'cart-drawer', // Identifies the section type.
        selector: '[tabindex="-1"]', // Selector for the focus management element.
      },
      {
        id: 'cart-icon-bubble',
        section: 'cart-icon-bubble', // Identifies the section type.
        selector: '.shopify-section', // Selector for the section element.
      },
    ]
  }
}

// Define the 'cart-drawer-items' custom element using the CartDrawerItems class.
window.customElements.define('cart-drawer-items', CartDrawerItems)
