class AnnouncementBar extends HTMLElement {
  constructor() {
    super()

    this.activeIndex = 0
    this.slides = this.querySelectorAll('.announcement-message')
    this.prevButton = this.querySelector('button[name="Previous"]')
    this.nextButton = this.querySelector('button[name="Next"]')
    this.autoplay = this.getAttribute('data-autoplay') === 'true'
    this.cycleSpeed = parseInt(this.getAttribute('data-cycle-speed'), 10) * 1000 // Convert seconds to milliseconds

    this.prevButton.addEventListener('click', () =>
      this.handleManualNavigation(-1)
    )
    this.nextButton.addEventListener('click', () =>
      this.handleManualNavigation(1)
    )

    if (this.autoplay) {
      this.startAutoplay()
    }
  }

  cycleSlides(direction) {
    this.slides[this.activeIndex].classList.remove('active')
    if (direction === 1) {
      this.activeIndex = (this.activeIndex + 1) % this.slides.length
    } else {
      this.activeIndex =
        (this.activeIndex - 1 + this.slides.length) % this.slides.length
    }
    this.slides[this.activeIndex].classList.add('active')
  }

  startAutoplay() {
    this.autoplayTimer = setInterval(() => {
      this.cycleSlides(1)
    }, this.cycleSpeed)
  }

  handleManualNavigation(direction) {
    this.stopAutoplay()
    this.cycleSlides(direction)
  }

  stopAutoplay() {
    if (this.autoplay) {
      clearInterval(this.autoplayTimer)
      this.autoplay = false
    }
  }

  connectedCallback() {
    this.updateNavOffset()
    window.addEventListener('scroll', () => this.updateNavOffset())
  }

  updateNavOffset() {
    const barHeight = this.offsetHeight
    const scrollY = window.scrollY
    const navOffset = Math.max(0, barHeight - scrollY)

    document.documentElement.style.setProperty('--nav-offset', `${navOffset}px`)
  }

  // Clean up interval when element is disconnected from the DOM
  disconnectedCallback() {
    this.stopAutoplay()
  }
}

window.customElements.define('core-announcement-bar', AnnouncementBar)
