import { removeTrapFocus, trapFocus } from '@/lib/a11y'

/**
 * DetailsModal class represents a custom web component that extends HTMLElement.
 * It provides methods for opening and closing a details modal dialog with accessibility considerations.
 */
export default class DetailsModal extends window.HTMLElement {
  constructor() {
    super()

    // Initialize detailsContainer and summaryToggle elements.
    this.detailsContainer = this.querySelector('details')
    this.summaryToggle = this.querySelector('summary')

    // Bind event listeners and setup accessibility attributes.
    this.detailsContainer.addEventListener('keyup', (event) => {
      if (event.code.toUpperCase() === 'ESCAPE') {
        this.close()
      }
    })

    this.summaryToggle.addEventListener('click', this.onSummaryClick.bind(this))

    const closeButton = this.querySelector('button[type="button"]')
    if (closeButton) {
      closeButton.addEventListener('click', this.close.bind(this))
    }

    this.summaryToggle.setAttribute('role', 'button')
  }

  /**
   * Checks if the modal is currently open.
   * @returns {boolean} - True if the modal is open, otherwise false.
   */
  isOpen() {
    return this.detailsContainer.hasAttribute('open')
  }

  /**
   * Callback triggered when the summary element is clicked.
   * Toggles the modal open or close state based on its current state.
   * @param {Event} event - The click event on the summary element.
   */
  onSummaryClick(event) {
    event.preventDefault()
    const isModalOpen = event.target.closest('details').hasAttribute('open')
    isModalOpen ? this.close() : this.open(event)
  }

  /**
   * Callback triggered when a click event occurs on the body.
   * Closes the modal if the click is outside the modal or on the modal overlay.
   * @param {Event} event - The click event on the body.
   */
  onBodyClick(event) {
    if (
      !this.contains(event.target) ||
      event.target.classList.contains('modal-overlay')
    ) {
      this.close(false)
    }
  }

  /**
   * Opens the modal dialog.
   * @param {Event} event - The event that triggered the opening action.
   */
  open(event) {
    this.onBodyClickEvent = this.onBodyClickEvent || this.onBodyClick.bind(this)
    event.target.closest('details').setAttribute('open', true)
    document.body.addEventListener('click', this.onBodyClickEvent)
    document.body.classList.add('overflow-hidden')

    // Trap focus within the modal content.
    trapFocus(
      this.detailsContainer.querySelector('[tabindex="-1"]'),
      this.detailsContainer.querySelector('input:not([type="hidden"])')
    )
  }

  /**
   * Closes the modal dialog.
   * @param {boolean} focusToggle - Whether to toggle focus back after closing.
   */
  close(focusToggle = true) {
    removeTrapFocus(focusToggle ? this.summaryToggle : null)
    this.detailsContainer.removeAttribute('open')
    document.body.removeEventListener('click', this.onBodyClickEvent)
    document.body.classList.remove('overflow-hidden')
  }
}
window.DetailsModal = DetailsModal
// Define the 'details-modal' custom element using the DetailsModal class.
window.customElements.define('details-modal', DetailsModal)
