import { debounce, onKeyUpEscape } from '../lib/utils'

class FacetFiltersForm extends HTMLElement {
  constructor() {
    super()
    this.onActiveFilterClick = this.onActiveFilterClick.bind(this)
    this.facetForm = null
    this.facetWrapper = null
  }

  connectedCallback() {
    this.facetForm = this.querySelector('form')
    if (this.facetForm) {
      this.facetForm.addEventListener(
        'input',
        this.debouncedOnSubmit.bind(this)
      )
    } else {
      console.warn('Form not found in facet-filters-form')
    }

    this.facetWrapper = this.querySelector('#FacetsWrapperDesktop')
    if (this.facetWrapper) {
      this.facetWrapper.addEventListener('keyup', onKeyUpEscape)
    } else {
      console.warn('FacetsWrapperDesktop not found in facet-filters-form')
    }

    const closeButtons = this.querySelectorAll(
      '[aria-controls="filters-drawer"]'
    )

    if (closeButtons) {
      closeButtons.forEach((button) => {
        button.addEventListener('click', this.handleCloseDrawer.bind(this))
      })
    }
  }

  handleCloseDrawer() {
    this.closest('filter-drawer').close()
  }

  disconnectedCallback() {
    if (this.facetForm) {
      this.facetForm.removeEventListener(
        'input',
        this.debouncedOnSubmit.bind(this)
      )
    }
    if (this.facetWrapper) {
      this.facetWrapper.removeEventListener('keyup', onKeyUpEscape)
    }

    const closeButtons = this.querySelectorAll(
      '[aria-controls="filters-drawer"]'
    )

    if (closeButtons) {
      closeButtons.forEach((button) => {
        button.removeEventListener('click', this.handleCloseDrawer.bind(this))
      })
    }
  }

  debouncedOnSubmit = debounce((event) => {
    this.onSubmitHandler(event)
  }, 250)

  static setListeners() {
    const onHistoryChange = (event) => {
      const searchParams = event.state
        ? event.state.searchParams
        : FacetFiltersForm.searchParamsInitial
      if (searchParams === FacetFiltersForm.searchParamsPrev) return
      FacetFiltersForm.renderPage(searchParams, null, false)
    }
    window.addEventListener('popstate', onHistoryChange)
  }

  static toggleActiveFacets(disable = true) {
    document.querySelectorAll('.js-facet-remove').forEach((element) => {
      element.classList.toggle('disabled', disable)
    })
  }

  static renderPage(searchParams, event, updateURLHash = true) {
    FacetFiltersForm.searchParamsPrev = searchParams
    const sections = FacetFiltersForm.getSections()
    // const countContainer = document.getElementById('ProductCount')
    const countContainerDesktop = document.getElementById('ProductCountDesktop')
    document
      .getElementById('ProductGridContainer')
      .querySelector('.collection')
      .classList.add('loading')
    if (countContainerDesktop) {
      countContainerDesktop.classList.add('loading')
    }

    sections.forEach((section) => {
      const url = `${window.location.pathname}?section_id=${section.section}&${searchParams}`
      const filterDataUrl = (element) => element.url === url

      FacetFiltersForm.filterData.some(filterDataUrl)
        ? FacetFiltersForm.renderSectionFromCache(filterDataUrl, event)
        : FacetFiltersForm.renderSectionFromFetch(url, event)
    })

    if (updateURLHash) FacetFiltersForm.updateURLHash(searchParams)
  }

  static renderSectionFromFetch(url, event) {
    fetch(url)
      .then((response) => response.text())
      .then((responseText) => {
        const html = responseText
        FacetFiltersForm.filterData = [
          ...FacetFiltersForm.filterData,
          { html, url },
        ]
        FacetFiltersForm.renderFilters(html, event, url)
        FacetFiltersForm.renderProductGridContainer(html)
        FacetFiltersForm.renderProductCount(html)
      })
  }

  static renderSectionFromCache(filterDataUrl, event) {
    const html = FacetFiltersForm.filterData.find(filterDataUrl).html
    FacetFiltersForm.renderFilters(html, event)
    FacetFiltersForm.renderProductGridContainer(html)
    FacetFiltersForm.renderProductCount(html)
  }

  static renderProductGridContainer(html) {
    document.getElementById('ProductGridContainer').innerHTML = new DOMParser()
      .parseFromString(html, 'text/html')
      .getElementById('ProductGridContainer').innerHTML

    document
      .getElementById('ProductGridContainer')
      .querySelectorAll('.scroll-trigger')
      .forEach((element) => {
        element.classList.add('scroll-trigger--cancel')
      })
  }

  static mergeQueryStrings(baseUrl, newUrl) {
    // Parse the URLs
    const base = new URL(baseUrl, 'http://example.com')
    const newU = new URL(newUrl)

    // Merge the search parameters
    const params = new URLSearchParams(newU.search)
    for (const [key, value] of base.searchParams) {
      console.log(key, value)
      if (key !== 'section_id') {
        params.set(key, value)
      }
    }
    newU.search = params.toString()

    // Return the merged URL
    return newU.origin + newU.pathname + newU.search
  }

  static renderTagFilters(html, url) {
    const template = html.getElementById('filter-template')
    if (template !== null) {
      template.content
        .querySelectorAll('[data-tag-filter]')
        .forEach((element) => {
          const currentElement = document
            .getElementById(element.id)
            .querySelector('.content')
          if (currentElement) {
            element
              .querySelector('.content')
              .querySelectorAll('a')
              .forEach((link) => {
                const newUrl = FacetFiltersForm.mergeQueryStrings(
                  url,
                  link.href
                )
                link.href = newUrl
              })
            currentElement.innerHTML =
              element.querySelector('.content').innerHTML
          }
        })
    } else {
      console.error('Element with id "filter-template" not found')
    }
  }

  /* 
  static renderPagination(html) {
    const newElement = html.querySelector('pagination-wrapper')
    const oldElement = document.querySelector('pagination-wrapper')
    if (newElement && oldElement) {
      oldElement.innerHTML = newElement.innerHTML
    }
  }
  */

  static renderProductCount(html) {
    const count = new DOMParser()
      .parseFromString(html, 'text/html')
      .getElementById('ProductCountDesktop').innerHTML
    const containerDesktop = document.getElementById('ProductCountDesktop')
    containerDesktop.innerHTML = count
    containerDesktop.classList.remove('loading')
  }

  static renderFilters(html, event, url = null) {
    const parsedHTML = new DOMParser().parseFromString(html, 'text/html')

    const targetTemplate = parsedHTML.getElementById('filter-template').content

    const facetDetailsElements = targetTemplate.querySelectorAll(
      '#FacetFiltersForm .js-filter, #FacetFiltersFormMobile .js-filter, #FacetFiltersPillsForm .js-filter'
    )
    const matchesIndex = (element) => {
      const jsFilter = event ? event.target.closest('.js-filter') : undefined
      return jsFilter ? element.dataset.index === jsFilter.dataset.index : false
    }
    const facetsToRender = Array.from(facetDetailsElements).filter(
      (element) => !matchesIndex(element)
    )
    const countsToRender = Array.from(facetDetailsElements).find(matchesIndex)

    facetsToRender.forEach((element) => {
      // if the target contains .content, we need to replace the content of the target so that the event listeners are not lost

      if (
        document.querySelector(
          `.js-filter[data-index="${element.dataset.index}"] .content`
        )
      ) {
        const targetContent = document.querySelector(
          `.js-filter[data-index="${element.dataset.index}"] .content`
        )
        targetContent.innerHTML = element.querySelector('.content').innerHTML
      } else {
        document.querySelector(
          `.js-filter[data-index="${element.dataset.index}"]`
        ).innerHTML = element.innerHTML
      }
    })

    FacetFiltersForm.renderActiveFacets(parsedHTML)
    FacetFiltersForm.renderAdditionalElements(parsedHTML)
    FacetFiltersForm.renderTagFilters(parsedHTML, url)

    if (countsToRender)
      FacetFiltersForm.renderCounts(
        countsToRender,
        event.target.closest('.js-filter')
      )
  }

  static renderActiveFacets(html) {
    const activeFacetElementSelectors = [
      '.active-facets-mobile',
      '.active-facets-desktop',
    ]

    activeFacetElementSelectors.forEach((selector) => {
      const activeFacetsElement = html.querySelector(selector)
      if (!activeFacetsElement) return
      document.querySelector(selector).innerHTML = activeFacetsElement.innerHTML
    })

    FacetFiltersForm.toggleActiveFacets(false)
  }

  static renderAdditionalElements(html) {
    const mobileElementSelectors = [
      '.mobile-facets__open',
      '.mobile-facets__count',
      '.sorting',
    ]

    mobileElementSelectors.forEach((selector) => {
      if (!html.querySelector(selector)) return
      document.querySelector(selector).innerHTML =
        html.querySelector(selector).innerHTML
    })

    /* 
    todo: this is a hack to get the mobile facets to work
    document
      .getElementById('FacetFiltersFormMobile')
      .closest('menu-drawer')
      .bindEvents()
    */
  }

  static renderCounts(source, target) {
    const targetElement = target.querySelector('.facets__selected')
    const sourceElement = source.querySelector('.facets__selected')

    const targetElementAccessibility = target.querySelector('.facets__summary')
    const sourceElementAccessibility = source.querySelector('.facets__summary')

    if (sourceElement && targetElement) {
      target.querySelector('.facets__selected').outerHTML =
        source.querySelector('.facets__selected').outerHTML
    }

    if (targetElementAccessibility && sourceElementAccessibility) {
      target.querySelector('.facets__summary').outerHTML =
        source.querySelector('.facets__summary').outerHTML
    }
  }

  static updateURLHash(searchParams) {
    history.pushState(
      { searchParams },
      '',
      `${window.location.pathname}${searchParams && '?'.concat(searchParams)}`
    )
  }

  static getSections() {
    return [
      {
        section: document.getElementById('product-grid').dataset.id,
      },
    ]
  }

  createSearchParams(form) {
    const formData = new FormData(form)
    return new URLSearchParams(formData).toString()
  }

  onSubmitForm(searchParams, event) {
    FacetFiltersForm.renderPage(searchParams, event)
  }

  onSubmitHandler(event) {
    event.preventDefault()
    document.dispatchEvent(new CustomEvent('theme:loading:start'), {
      bubbles: true,
    })

    const sortFilterForms = document.querySelectorAll('facet-filters-form form')
    if (event.srcElement.className === 'mobile-facets__checkbox') {
      const searchParams = this.createSearchParams(event.target.closest('form'))
      this.onSubmitForm(searchParams, event)
    } else {
      const forms = []
      const isMobile =
        event.target.closest('form').id === 'FacetFiltersFormMobile'

      sortFilterForms.forEach((form) => {
        if (!isMobile) {
          if (
            form.id === 'FacetSortForm' ||
            form.id === 'FacetFiltersForm' ||
            form.id === 'FacetSortDrawerForm'
          ) {
            const noJsElements = document.querySelectorAll('.no-js-list')
            noJsElements.forEach((el) => el.remove())
            forms.push(this.createSearchParams(form))
          }
        } else if (form.id === 'FacetFiltersFormMobile') {
          forms.push(this.createSearchParams(form))
        }
      })
      this.onSubmitForm(forms.join('&'), event)
    }
    document.dispatchEvent(new CustomEvent('theme:loading:end'), {
      bubbles: true,
    })
  }

  onActiveFilterClick(event) {
    event.preventDefault()
    FacetFiltersForm.toggleActiveFacets()
    const url =
      event.currentTarget.href.indexOf('?') === -1
        ? ''
        : event.currentTarget.href.slice(
            event.currentTarget.href.indexOf('?') + 1
          )
    FacetFiltersForm.renderPage(url)
  }
}

FacetFiltersForm.filterData = []
FacetFiltersForm.searchParamsInitial = window.location.search.slice(1)
FacetFiltersForm.searchParamsPrev = window.location.search.slice(1)
customElements.define('facet-filters-form', FacetFiltersForm)
FacetFiltersForm.setListeners()
