/**
 * VariantSelects Web Component.
 * This component handles product variant selection and updates the interface
 * accordingly.
 *
 * @extends window.HTMLElement
 */

export default class VariantSelects extends window.HTMLElement {
  /**
   * Generate the srcset for a variant image.
   * @param {string} url
   * @returns
   */

  generateSrcset(url) {
    // Check if the URL is valid
    if (!url) {
      return ''
    }

    // Generate the srcset
    const widths = [550, 1100]
    const srcset = widths
      .map((width) => `${url}&width=${width} ${width}w`)
      .join(', ')

    return srcset
  }

  /**
   * Updates the product image if there is a variant image attached
   * @param {Object} currentVariant - The current variant object.
   */

  updateVariantImage(currentVariant) {
    if (
      currentVariant.featured_image?.src !== undefined &&
      currentVariant.featured_image?.src !== ''
    ) {
      const src = currentVariant.featured_image.src
      const srcSet = this.generateSrcset(src)
      const targetSection = this.closest(`section`)

      if (!targetSection) return

      const targetImage = targetSection.querySelector(
        `[data-media-grid="${this.dataset.productId}"] img`
      )

      if (targetImage) {
        targetImage.src = src
        targetImage.srcset = srcSet
      }
    }
  }

  /**
   * For when a split variant link is clicked, within a quickbuy context.
   * @param {Event} e - The click event object.
   */

  handleSplitVariantClick(e) {
    e.preventDefault()
    const targetHandle = e.target.dataset.splitVariant

    this.closest('quick-buy-modal').open(e, targetHandle)
  }

  /**
   * Called when the element is connected to the document's DOM.
   */
  connectedCallback() {
    // Add an event listener for the 'change' event.
    this.addEventListener('change', this.onVariantChange)

    // Set the 'bisEnabled' property based on the data attribute 'data-bisEnabled'.
    this.bisEnabled = this.dataset.bisEnabled === 'true'

    document.addEventListener(
      'variant:changed',
      this.onVariantChangeListener.bind(this)
    )

    if (this.dataset.context === 'quickbuy') {
      const quickBuySplitLinks = this.querySelectorAll('[data-split-variant]')

      if (quickBuySplitLinks !== undefined && quickBuySplitLinks.length > 0) {
        quickBuySplitLinks.forEach((link) => {
          link.addEventListener(
            'click',
            this.handleSplitVariantClick.bind(this)
          )
        })
      }
    }

    this.updateOptions()
    this.updateMasterId()
    this.disableTopDown()
  }

  /**
   * Applies the provided variant to the element.
   * @param {Object} variant - The variant object with key-value pairs.
   */
  applyVariant(variant) {
    const fieldsets = this.querySelectorAll('fieldset')

    fieldsets.forEach((fieldset) => {
      const fieldsetKey = fieldset.dataset.optionKey
      const value = variant[fieldsetKey]
      if (!value) return
      const radio = fieldset.querySelector(`input[value="${value}"]`)
      radio.checked = true
    })

    const selects = this.querySelectorAll('select')

    selects.forEach((select) => {
      const selectKey = select.dataset.optionKey
      const value = variant[selectKey]
      if (!value) return
      select.value = value
    })
  }

  /**
   * Handles the 'variant:changed' event.
   * @param {Event} event - The event object containing details about the changed variant.
   */
  onVariantChangeListener(event) {
    window.console.log(event)

    if (
      this.dataset.productId === event.detail.id &&
      event.detail.initiator !== this
    ) {
      this.currentVariant = event.detail.variant
      this.applyVariant(event.detail.variant)
      this.onVariantChange(null, true)
    }
  }

  /**
   * Handles the changing of variant labels, if present.
   * @param {Object} variant - The event object containing details about the changed variant.
   */
  updateVariantLabelNames(variant) {
    this.querySelectorAll('[data-option-index]').forEach((label) => {
      const index = label.dataset.optionIndex
      const targetKey = `option${parseInt(index)}`
      if (!variant) return
      const value = variant[targetKey]
      if (!value) return
      label.textContent = value
    })
  }

  /**
   * Called when the element is disconnected from the document's DOM.
   */
  disconnectedCallback() {
    // Remove the event listener for the 'change' event.

    this.removeEventListener('change', this.onVariantChange)

    this.removeEventListener('change', this.onVariantChange)
    document.removeEventListener(
      'variant:changed',
      this.onVariantChangeListener
    )
  }

  /**
   * Handles changes in the variant.
   * @param {Event} e - The change event object.
   * @param {boolean} silenceEvent - A flag to determine if certain actions should be silenced.
   */
  onVariantChange(e, silenceEvent = false) {
    this.updateOptions()
    this.updateMasterId()
    this.disableTopDown()
    this.toggleAddButton(true, '', false)
    this.removeErrorMessage()
    this.updateVariantLabelNames(this.currentVariant)

    if (!this.currentVariant) {
      this.toggleAddButton(true, '', true)
      this.setUnavailable()
    } else {
      if (this.dataset.context === 'pdp') {
        this.updateURL()
      }
      this.updateVariantInput(silenceEvent)
      this.renderProductInfo()
      this.applyQuickbuy()
    }
  }

  /**
   * Initializes the quickbuy functionality.
   */
  applyQuickbuy() {
    const productForm = document.getElementById(`${this.dataset.productFormId}`)
    if (!productForm) return

    const quickbuyTemplate = productForm.querySelector(
      '[data-quickbuy-template]'
    )
    const quickbuyTarget = productForm.querySelector('[data-quickbuy-target]')

    if (!quickbuyTemplate || !quickbuyTarget) return

    const quickbuyTemplateContent = quickbuyTemplate.content.cloneNode(true)
    quickbuyTarget.appendChild(quickbuyTemplateContent)
    quickbuyTemplate.remove()

    window.Shopify?.PaymentButton?.init()
  }

  /**
   * Updates the options based on the selected values in the dropdowns.
   */
  updateOptions() {
    this.options = Array.from(
      this.querySelectorAll('select'),
      (select) => select.value
    )
  }

  /* 
    Disable options that are not in the valid variants
    This is a top-down approach, where the first selected option is used to filter the rest of the options
  */

  disableTopDown() {
    // get selected Options
    const selectedOptions = this.options
    if (!selectedOptions) return
    const firstSelectedOption = selectedOptions[0]
    if (!firstSelectedOption) return

    // locate all valid variants that share the first selected option

    const validVariants = this.getVariantData().filter((variant) =>
      variant.options.includes(firstSelectedOption)
    )

    const flattenedVariants = validVariants.map((variant) => variant.options)

    // disable all options that are not in the valid variants

    const fieldsets = this.querySelectorAll('fieldset')

    fieldsets.forEach((fieldset) => {
      const optionKey = fieldset.dataset.optionKey

      if (!optionKey) return

      const optionIndex = optionKey.replace('option', '')
      const options = fieldset.querySelectorAll('input')
      const realIndex = parseInt(optionIndex) - 1
      const flattenedValues = flattenedVariants.map(
        (variant) => variant[realIndex]
      )
      options.forEach((option) => {
        if (option.value && realIndex !== 0) {
          if (!flattenedValues.includes(option.value)) {
            option.disabled = true
          } else {
            option.disabled = false
          }
        }
      })
    })
  }

  /**
   * Updates the master ID by finding the matching variant based on options.
   */
  updateMasterId() {
    this.currentVariant = this.getVariantData().find((variant) =>
      variant.options.every((option, index) => this.options[index] === option)
    )
  }

  /**
   * Updates the URL based on the current variant.
   */
  updateURL() {
    if (!this.currentVariant || this.dataset.updateUrl === 'false') return
    window.history.replaceState(
      {},
      '',
      `${this.dataset.url}?variant=${this.currentVariant.id}`
    )
  }

  /**
   * Updates the variant input value and emits a global event if necessary.
   * @param {boolean} silenceEvent - A flag to determine if the global event should be silenced.
   */
  updateVariantInput(silenceEvent = false) {
    // Select all relevant product forms based on provided dataset values.
    const productForms = document.querySelectorAll(
      `#${this.dataset.productFormId}, #product-form-installment-${this.dataset.section}`
    )

    // Update each product form's input value and dispatch a change event.
    productForms.forEach((productForm) => {
      const input = productForm.querySelector('input[name="id"]')
      input.value = this.currentVariant.id

      // Notify listeners of the change in input.
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })

    // If not silenced, emit a global event for other components to listen to.
    if (!silenceEvent) {
      const event = new CustomEvent('variant:changed', {
        bubbles: true,
        detail: {
          variant: this.currentVariant,
          id: this.dataset.productId,
          initiator: this,
        },
      })

      // Dispatch the custom event.
      this.dispatchEvent(event)
    }
  }

  /**
   * Removes the error message from the closest section's product form.
   */
  removeErrorMessage() {
    const section = this.closest('section')
    if (!section) return

    const productForm = section.querySelector('product-form')
    if (productForm) productForm.handleErrorMessage()
  }

  /**
   * Updates the text content of a sticky mobile button based on the variant's availability.
   * @param {HTMLElement} element - The button element to update.
   */
  setStickyMobile(element) {
    if (!element) return

    const addButtonText = element.querySelector('span')

    // Update the button's text content based on variant availability and bisEnabled status.
    if (this.currentVariant.available) {
      addButtonText.textContent = window.variantStrings.addToCart
    } else {
      addButtonText.textContent = this.bisEnabled
        ? window.variantStrings.notifyMe
        : window.variantStrings.soldOut
    }
  }

  hotReloadDynamicPortions(html) {
    const dynamicPortions = html.querySelectorAll('[data-dynamic-id]')

    dynamicPortions.forEach((portion) => {
      const dynamicId = portion.dataset.dynamicId
      const target = document.querySelector(`[data-dynamic-id="${dynamicId}"]`)
      if (!target) return
      target.innerHTML = portion.innerHTML
    })
  }

  /**
   * Renders product information based on the current variant.
   */
  renderProductInfo() {
    const fetchURL = `${this.dataset.url}?variant=${
      this.currentVariant.id
    }&section_id=${this.dataset.originalSection || this.dataset.section}`

    fetch(fetchURL)
      .then((response) => response.text())
      .then((responseText) => {
        const html = new window.DOMParser().parseFromString(
          responseText,
          'text/html'
        )

        this.updateVariantImage(this.currentVariant)

        // Update product price based on fetched data.
        const destination = document.getElementById(
          `price-${this.dataset.section}`
        )
        const source = html.getElementById(
          `price-${this.dataset.originalSection || this.dataset.section}`
        )
        if (source && destination) destination.innerHTML = source.innerHTML

        // Update BNPL information if there are changes.
        const bnplDestination = document.getElementById(
          `bnpl-${this.dataset.section}`
        )
        const bnplSource = html.getElementById(
          `bnpl-${this.dataset.originalSection || this.dataset.section}`
        )
        if (
          bnplSource &&
          bnplDestination &&
          bnplSource.innerText.trim() !== bnplDestination.innerText.trim()
        ) {
          bnplDestination.innerHTML = bnplSource.innerHTML
        }

        this.hotReloadDynamicPortions(html)

        // Display the price if hidden.
        const price = document.getElementById(`price-${this.dataset.section}`)
        if (price) price.classList.remove('invisible')

        // Update sticky mobile button if applicable.
        const stickyMobile = document.querySelector('product-sticky-atc-mobile')
        if (stickyMobile && this.dataset.context === 'pdp') {
          this.setStickyMobile(stickyMobile)
        }

        // Handle BIS (Back in Stock) button states.
        if (this.bisEnabled) {
          if (!this.currentVariant.available) {
            this.toggleAddButton(false, window.variantStrings.notifyMe)
            this.changeBisTarget(true)
            return
          }
          this.changeBisTarget(false)
        }

        // Handle Add to Cart button states.
        this.toggleAddButton(
          !this.currentVariant.available,
          !this.currentVariant.available
            ? window.variantStrings.soldOut
            : window.variantStrings.addToCart
        )
      })
  }

  /**
   * Toggles the BIS (Back in Stock) target for the product form.
   * @param {boolean} enable - Whether to enable or disable the BIS target.
   */
  changeBisTarget(enable = false) {
    const productForm = document.getElementById(`${this.dataset.productFormId}`)

    // if productForm is not found, we don't need to do anything
    if (!productForm) return

    // Look for add buttons both inside and outside of the product form.
    const addButton =
      productForm.querySelector('[name="add"]') ||
      productForm.parentElement.parentElement.querySelector(
        'button[name="add"]'
      )

    const bisTarget = addButton.dataset.bisTarget

    // If bisTarget is undefined, another bisTarget may exist on the page, so we attempt to find it.
    if (!bisTarget && enable) {
      const bisValue = `bis-form[data-product-id="${this.dataset.productId}"]`
      const foundBisTarget = document.querySelector(bisValue)
      if (!foundBisTarget) return
      addButton.setAttribute('data-scroll-to', bisValue)
    } else if (!enable) {
      addButton.removeAttribute('data-scroll-to')
    }

    if (!addButton || !bisTarget) return

    // Toggle data-bis-target based on the enable flag.
    if (enable && bisTarget) {
      addButton.setAttribute('form', bisTarget)
      this.closest('[product-info]')
        .querySelector(`#${bisTarget}`)
        .parentElement.classList.remove('hidden')
    } else {
      addButton.setAttribute('form', `${this.dataset.productFormId}`)
      this.closest('[product-info]')
        .querySelector(`#${bisTarget}`)
        .parentElement.classList.add('hidden')
    }
  }

  /**
   * Toggles the state and text of the Add to Cart button.
   * @param {boolean} disable - Whether to disable the button.
   * @param {string} text - The text to display on the button.
   * @param {boolean} modifyClass - Whether to modify the class of the button.
   */
  toggleAddButton(disable = true, text, modifyClass = true) {
    const productForm = document.getElementById(`${this.dataset.productFormId}`)
    if (!productForm) return

    // Look for add buttons both inside and outside of the product form.
    const addButton =
      productForm.querySelector('[name="add"]') ||
      productForm.parentElement.parentElement.querySelector(
        'button[name="add"]'
      )

    const addButtonText = addButton.querySelector('span')

    if (!addButton) return

    // Toggle button's disabled state and update its text content.
    if (disable) {
      addButton.setAttribute('disabled', 'disabled')
      if (text && addButtonText) addButtonText.textContent = text
    } else {
      addButton.removeAttribute('disabled')
      addButton.classList.remove('!hidden')
      productForm.classList.remove('!hidden')

      if (text && addButtonText) {
        addButtonText.textContent = text
        if (
          addButtonText.textContent === window.variantStrings.notifyMe &&
          addButton.dataset.clone === undefined
        ) {
          addButton.classList.add('!hidden')
          productForm.classList.add('!hidden')
        }
      } else {
        addButtonText.textContent = window.variantStrings.addToCart
      }
    }
  }

  /**
   * Sets the product as unavailable.
   */
  setUnavailable() {
    const productForm = document.getElementById(`${this.dataset.productFormId}`)

    // Look for add buttons both inside and outside of the product form.
    const addButton =
      productForm.querySelector('[name="add"]') ||
      productForm.parentElement.parentElement.querySelector(
        'button[name="add"]'
      )

    const addButtonText = addButton.querySelector('span')
    const price = document.getElementById(`price-${this.dataset.section}`)

    if (!addButton) return

    // Update button text and hide the price.
    addButtonText.textContent = window.variantStrings.unavailable
    if (price) price.classList.add('invisible')
  }

  /**
   * Retrieves variant data from the element's content.
   * @returns {Object} The parsed variant data.
   */
  getVariantData() {
    if (!this.variantData) {
      this.variantData = JSON.parse(
        this.querySelector('[type="application/json"]').textContent
      )
    }
    return this.variantData
  }
}
window.VariantSelects = VariantSelects
window.customElements.define('variant-selects', VariantSelects)
