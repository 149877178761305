class Drawer extends window.HTMLElement {
  parent = this.parentNode

  constructor() {
    super()

    const trigger = document.querySelectorAll(`[aria-controls="${this.id}"]`)

    if (trigger && trigger.length > 0) {
      trigger.forEach((trigger) => {
        trigger.addEventListener('click', this.open.bind(this))
      })
    }

    document.addEventListener('keyup', (evt) => {
      if (evt.code === 'Escape') {
        this.close()
      }
    })
  }

  disconnectedCallback() {
    const trigger = document.querySelectorAll(`[aria-controls="${this.id}"]`)

    // check if triggers exist before removing event listeners

    if (trigger && trigger.length > 0) {
      trigger.forEach((trigger) => {
        trigger.removeEventListener('click', this.open)
      })
    }
  }

  open() {
    this.dispatchEvent(new window.CustomEvent('drawer:open'))

    if (this.shouldLock) {
      document.body.classList.add('overflow-hidden')
    }
    if (this.shouldTrapFocus) {
      this.trapFocus(this)
    }

    if (this.shouldAppendToBody) {
      // move the drawer to the end of the body
      document.body.appendChild(this)
    }

    // Use requestAnimationFrame to trigger the transition

    this.setAttribute('open', true)

    const shadowRootContent = this.shadowRoot.querySelector('.drawer-content')

    requestAnimationFrame(() => {
      shadowRootContent.style.opacity = 1
      this.classList.add('open')
    })
  }

  trapFocus(container, focusElement) {
    const focusableElements = container.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    )
    const firstFocusableElement =
      focusElement ||
      focusableElements[0] ||
      this.shadowRoot.querySelector('slot')

    firstFocusableElement.focus()
  }

  close() {
    const shadowRootContent = this.shadowRoot.querySelector('.drawer-content')
    requestAnimationFrame(() => {
      shadowRootContent.style.opacity = 0
      this.classList.remove('open')
    })
    this.dispatchEvent(new window.CustomEvent('drawer:close'))
    if (this.shouldLock) {
      document.body.classList.remove('overflow-hidden')
    }

    if (this.shouldAppendToBody) {
      // if the parent includes an element of the same id, kill it off. it's a zombie.

      const parentElement = this.parent.querySelector(`#${this.id}`)

      if (parentElement) {
        parentElement.remove()
      }

      // move the drawer back to its original position
      this.parent.appendChild(this)
    }

    document.dispatchEvent(new CustomEvent('theme:loading:cancel'), {
      bubbles: true,
    })

    this.removeAttribute('open')
  }

  get shouldLock() {
    return true
  }

  get shouldTrapFocus() {
    return true
  }

  get shouldAppendToBody() {
    return true
  }

  connectedCallback() {
    // assume there are multiple triggers for the same drawer

    this.setAttribute('role', 'dialog')
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' })
      this.render()
    }
  }

  render() {
    this.shadowRoot.innerHTML = `
        <style>
          :host {
            display: none;
            position: fixed;
            z-index: 9999999;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            overflow: auto;
            opacity: 0; /* Initial opacity set to 0 */
            visibility: hidden; /* Initial visibility set to hidden */
            backdrop-filter: blur(0px); 
            -webkit-backdrop-filter: blur(0px);
            transition: opacity 300ms ease-in-out, backdrop-filter 300ms ease-in-out, -webkit-backdrop-filter 300ms ease-in-out;
            pointer-events: none;
            overflow: hidden;
          }
  
          .drawer-content {
            display: flex;
            justify-content: start;
            align-items: center;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
          }
  
          .drawer-background {
            background-color: rgba(0, 0, 0, 0.4);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
  
          :host([open]) {
            display: block !important;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
          }

          :host([slide-mobile="top"]) .drawer-content{
            justify-content: center;
            align-items: flex-start;
          }

          :host([slide-mobile="bottom"]) .drawer-content{
            justify-content: center;
            align-items: flex-end;
          }

          :host([slide-desktop="top"]) .drawer-content{
            justify-content: center;
            align-items: flex-start;
          }

          :host([slide-mobile="right"]) .drawer-content{
            justify-content: flex-end;
          }

          :host([slide-mobile="right"]) .drawer-content{
            justify-content: flex-end;
          }

          :host([slide-desktop="right"]) .drawer-content{
            justify-content: flex-end;
          }
          :host([slide-desktop="left"]) .drawer-content{
            justify-content: flex-start;
          }

  
        </style>
        <div class="drawer-content">
          <div class="drawer-background" aria-hidden="true"></div>
          <slot></slot>
        </div>
      `

    const shadowRootContent = this.shadowRoot.querySelector('.drawer-content')

    shadowRootContent.addEventListener('click', (evt) => {
      if (evt.target === shadowRootContent) {
        this.close()
      }
    })
  }
}

window.Drawer = Drawer
