/**
 * BisForm class represents a custom web component that extends HTMLElement.
 * It provides functionality to handle form submission for back-in-stock notifications.
 */
class BisForm extends window.HTMLElement {
  constructor() {
    super()
    // Get relevant data attributes from the dataset.
    this.listId = this.dataset.listId
    this.apiKey = this.dataset.apiKey
    this.prodId = this.dataset.productId
  }

  /**
   * Callback triggered when the component is connected to the DOM.
   * Sets up event listeners and references to form elements.
   */
  connectedCallback() {
    this.form = this.querySelector('form')
    this.successWrapper = this.querySelector('[bis-success]')
    this.messageWrapper = this.querySelector('[bis-message]')
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
  }

  /**
   * Callback triggered when the component is disconnected from the DOM.
   * Removes the form submit event listener.
   */
  disconnectedCallback() {
    this.form.removeEventListener('submit', this.onSubmitHandler.bind(this))
  }

  /**
   * Handles form submission for back-in-stock notifications.
   * @param {Event} evt - The form submit event.
   */
  onSubmitHandler(evt) {
    evt.preventDefault()

    // Identify the product form related to the clicked submit button.
    const productForm = evt.submitter
      .closest('section')
      .querySelector('product-form form')
    evt.submitter.classList.add('loading')

    // Get variant input from the product form.
    const variantInput = productForm.querySelector('[name="id"]')

    // Create a FormData object from the form.
    const formData = new window.FormData(this.form)

    // Extract form data values.
    const email = formData.get('email')
    const subscribeForNewsletter = formData.get('subscribeForNewsletter')
    const subscribeForNewsletterBool = subscribeForNewsletter === 'on'

    // Prepare data for sending.
    const data = {
      a: this.apiKey,
      email,
      variant: variantInput.value,
      product: this.prodId,
      platform: 'shopify',
      subscribe_for_newsletter: subscribeForNewsletterBool,
      g: this.listId,
    }

    // Create a FormData object for the fetch request.
    const realFormData = new FormData()

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        realFormData.append(key, data[key])
      }
    }

    // Send a POST request to the back-in-stock notification API.
    fetch('https://a.klaviyo.com/onsite/components/back-in-stock/subscribe', {
      method: 'POST',
      body: realFormData,
    })
      .then((response) => {
        // Reset the form, show success message, and update button state.
        this.form.reset()
        this.form.classList.add('hidden')
        this.messageWrapper.classList.add('hidden')
        this.successWrapper.classList.remove('hidden')

        try {
          evt.submitter.classList.remove('loading')
        } catch (e) {
          window.console.log(e)
        }
      })
      .catch((error) => {
        // Reset the form, show error message, and update button state.
        this.form.reset()
        this.form.messageWrapper.textContent = error
      })
  }
}

// Define the 'bis-form' custom element using the BisForm class.
window.customElements.define('bis-form', BisForm)
