import { debounce, fetchConfig } from '@/lib/utils'
import { trapFocus } from '@/lib/a11y'

/**
 * Represents a custom element for managing cart items and quantities.
 */
export default class CartItems extends window.HTMLElement {
  constructor() {
    super()

    // Get the line item status element from the DOM.
    this.lineItemStatusElement =
      document.getElementById('shopping-cart-line-item-status') ||
      document.getElementById('CartDrawer-LineItemStatus')

    // Initialize a debounced version of the onChange method.
    this.debouncedOnChange = debounce((event) => {
      this.onChange(event)
    }, 300)

    // Attach the debounced onChange event listener.
    this.addEventListener('change', this.debouncedOnChange)
  }

  /**
   * Handles the change event for quantity inputs.
   * Updates the quantity and triggers relevant actions.
   * @param {object} event - The change event object.
   */
  onChange(event) {
    // Update the quantity and perform related actions.
    this.updateQuantity(
      event.target.dataset.index,
      event.target.value,
      document.activeElement.getAttribute('name')
    )
  }

  /**
   * Returns an array of objects representing sections to render.
   * Each object includes the section's id, section value, and a selector.
   * @returns {Array} An array of section objects.
   */
  getSectionsToRender() {
    return [
      {
        id: 'main-cart-items',
        section: document.getElementById('main-cart-items').dataset.id,
        selector: '.js-contents',
      },
      {
        id: 'MainCart',
        section: document.getElementById('main-cart-items').dataset.id,
        selector: '.prog-wrapper',
      },
      {
        id: 'cart-icon-bubble',
        section: 'cart-icon-bubble',
        selector: '.shopify-section',
      },
      {
        id: 'cart-live-region-text',
        section: 'cart-live-region-text',
        selector: '.shopify-section',
      },
      {
        id: 'cart-subtotal',
        section: 'cart-subtotal',
        selector: '.shopify-section',
      },
    ]
  }

  /**
   * Update the quantity of a cart item and perform related actions.
   * @param {string} line - The line item identifier.
   * @param {number} quantity - The new quantity value.
   * @param {string} name - The name attribute of the input element.
   */
  updateQuantity(line, quantity, name) {
    // Enable loading state for the line item.
    this.enableLoading(line)

    // Prepare the request body.
    const body = JSON.stringify({
      line,
      quantity,
      sections: this.getSectionsToRender().map((section) => section.section),
      sections_url: window.location.pathname,
    })

    // Send a fetch request to update the cart quantity.
    fetch(`${window.routes.cart_change_url}`, { ...fetchConfig(), ...{ body } })
      .then((response) => {
        return response.text()
      })
      .then((state) => {
        // Parse the response state JSON.
        const parsedState = JSON.parse(state)
        const quantityElement =
          document.getElementById(`Quantity-${line}`) ||
          document.getElementById(`Drawer-quantity-${line}`)
        const items = document.querySelectorAll('.cart-item')

        if (parsedState.errors) {
          quantityElement.value = quantityElement.getAttribute('value')
          this.updateLiveRegions(line, parsedState.errors)
          return
        }

        // Update the quantity value of the input element.
        // because the quantity value is not updated in the DOM
        if (name === 'no-qty-element-update' && quantityElement) {
          quantityElement.value = quantity
        }

        // Toggle 'is-empty' class based on item count.
        this.classList.toggle('is-empty', parsedState.item_count === 0)
        const cartDrawerWrapper = document.querySelector('cart-drawer')

        if (cartDrawerWrapper) {
          cartDrawerWrapper.classList.toggle(
            'is-empty',
            parsedState.item_count === 0
          )
        }

        // Update sections based on parsed state data.
        this.getSectionsToRender().forEach((section) => {
          const parentElement =
            document
              .getElementById(section.id)
              .querySelector(section.selector) ||
            document.getElementById(section.id)

          // Process and replace children content.
          const children = Array.from(parentElement.children)
          const newChildrenContent = this.getSectionInnerHTML(
            parsedState.sections[section.section],
            section.selector
          )
          const newChildren = new DOMParser().parseFromString(
            newChildrenContent,
            'text/html'
          ).body.children

          children.forEach((child, index) => {
            if (!child.hasAttribute('data-static') && newChildren[index]) {
              parentElement.replaceChild(
                newChildren[index].cloneNode(true),
                child
              )
            }
          })

          // Emit a custom event 'cart:quantity:update'.
          const newChildrenArray = Array.from(newChildren)
          const event = new CustomEvent(
            'cart:quantity:update',
            {
              detail: {
                line,
                quantity,
                name,
                parsedState,
                newChildren: newChildrenArray,
              },
            },
            { bubbles: true }
          )
          document.dispatchEvent(event)
        })

        const updatedValue = parsedState.items[line - 1]
          ? parsedState.items[line - 1].quantity
          : undefined
        let message = ''
        if (
          items.length === parsedState.items.length &&
          updatedValue !== parseInt(quantityElement.value)
        ) {
          if (typeof updatedValue === 'undefined') {
            message = window.cartStrings.error
          } else {
            message = window.cartStrings.quantityError.replace(
              '[quantity]',
              updatedValue
            )
          }
        }
        this.updateLiveRegions(line, message)

        const lineItem =
          document.getElementById(`CartItem-${line}`) ||
          document.getElementById(`CartDrawer-Item-${line}`)
        if (lineItem && lineItem.querySelector(`[name="${name}"]`)) {
          if (cartDrawerWrapper) {
            trapFocus(
              cartDrawerWrapper,
              lineItem.querySelector(`[name="${name}"]`)
            )
          }
        } else if (parsedState.item_count === 0 && cartDrawerWrapper) {
          trapFocus(
            cartDrawerWrapper.querySelector('#CartDrawer'),
            cartDrawerWrapper.querySelector('[tabindex="-1"]')
          )
        } else if (document.querySelector('.cart-item') && cartDrawerWrapper) {
          trapFocus(
            cartDrawerWrapper,
            document.querySelector('.cart-item-name')
          )
        }
      })
      .catch((e) => {
        const errors =
          document.getElementById('cart-errors') ||
          document.getElementById('CartDrawer-CartErrors')
        errors.textContent = window.cartStrings.error
      })
      .finally(() => {
        // Handle errors and disable loading state.
        this.querySelectorAll('.loading-overlay').forEach((overlay) =>
          overlay.classList.add('hidden')
        )
        this.disableLoading()
      })
  }

  /**
   * Update live regions for accessibility and provide feedback to users.
   * @param {string} line - The line item identifier.
   * @param {number} message - The error message to display.
   */
  updateLiveRegions(line, message) {
    const lineItemError =
      document.getElementById(`Line-item-error-${line}`) ||
      document.getElementById(`CartDrawer-LineItemError-${line}`)
    if (lineItemError) lineItemError.innerHTML = message

    // Hide the line item status element for accessibility.
    this.lineItemStatusElement.setAttribute('aria-hidden', true)

    // Update and show the cart status live region.
    const cartStatus =
      document.getElementById('cart-live-region-text') ||
      document.getElementById('CartDrawer-LiveRegionText')
    cartStatus.setAttribute('aria-hidden', false)

    // Hide the cart status live region after a delay.
    setTimeout(() => {
      cartStatus.setAttribute('aria-hidden', true)
    }, 1000)
  }

  /**
   * Get the inner HTML of a specific selector within the provided HTML content.
   * @param {string} html - The HTML content to parse.
   * @param {string} selector - The selector of the element to retrieve inner HTML from.
   * @returns {string} The inner HTML content of the selected element.
   */
  getSectionInnerHTML(html, selector) {
    return new window.DOMParser()
      .parseFromString(html, 'text/html')
      .querySelector(selector).innerHTML
  }

  /**
   * Enable loading state for a specific cart item.
   * @param {string} line - The line item identifier.
   */
  enableLoading(line) {
    // Add 'loading' class to the main cart items element.
    const mainCartItems =
      document.getElementById('main-cart-items') ||
      document.getElementById('CartDrawer-CartItems')
    mainCartItems.classList.add('loading')

    // Show loading overlays for both cart item and cart drawer item.
    const cartItemElements = this.querySelectorAll(
      `#CartItem-${line} .loading-overlay`
    )

    const cartDrawerItemElements = this.querySelectorAll(
      `#CartDrawer-Item-${line} .loading-overlay`
    )

    ;[...cartItemElements, ...cartDrawerItemElements].forEach((overlay) =>
      overlay.classList.remove('hidden')
    )

    // Blur the active element and show line item status.
    document.activeElement.blur()
    this.lineItemStatusElement.setAttribute('aria-hidden', false)
  }

  /**
   * Disable loading state for cart items.
   */
  disableLoading() {
    // Remove 'loading' class from the main cart items element.
    const mainCartItems =
      document.getElementById('main-cart-items') ||
      document.getElementById('CartDrawer-CartItems')
    mainCartItems.classList.remove('loading')
  }
}

window.CartItems = CartItems

window.customElements.define('cart-items', CartItems)
