export function fetchConfig(type = 'json') {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: `application/${type}`,
    },
  }
}

export function debounce(fn, wait) {
  let t
  return (...args) => {
    clearTimeout(t)
    t = setTimeout(() => fn.apply(this, args), wait)
  }
}

export const isMobile = () => {
  return window.matchMedia('(max-width: 1024px)').matches
}

export const onKeyUpEscape = (event) => {
  if (event.code.toUpperCase() !== 'ESCAPE') return

  const openDetailsElement = event.target.closest('details[open]')
  if (!openDetailsElement) return

  const summaryElement = openDetailsElement.querySelector('summary')
  openDetailsElement.removeAttribute('open')
  summaryElement.setAttribute('aria-expanded', false)
  summaryElement.focus()
}

export const spacingToVarName = (spacing) => {
  // get everything after the first dash

  if (!spacing) return

  const spacingMap = spacing.split('-').slice(1).join('-')

  switch (spacingMap) {
    case 'gutter-mobile':
      return '--gutter-mobile'
    case 'gutter-desktop':
      return '--gutter-desktop'
    case 'gutter-x':
      return '--gutter-x'
    case 'gutter-y':
      return '--gutter-y'
    case 'gutter-x-half':
      return '--gutter-x-half'
    case 'gutter-y-half':
      return '--gutter-y-half'
    case 'gutter-y-double':
      return '--gutter-y-double'
    case 'gutter-x-double':
      return '--gutter-x-double'
    default:
      return spacing
  }
}

export const buildProductFormId = (productId, sectionId, context) => {
  // 'product-form-' | append: section.id | append: product.id | append: context
  return `product-form-${sectionId}${productId}${context}`
}
