function media({ query }) {
  const mediaQuery = window.matchMedia(query)
  return new Promise(function (resolve) {
    if (mediaQuery.matches) {
      resolve(true)
    } else {
      try {
        mediaQuery.addEventListener('change', resolve, { once: true })
      } catch (e1) {
        try {
          mediaQuery.addEventListener(
            (e) => {
              resolve(true)
            },
            { once: true }
          )
        } catch (e2) {
          console.error(e2)
        }
      }
    }
  })
}

function interaction() {
  return new Promise(function (resolve) {
    function handleInteractionEvent() {
      // Remove the event listeners once the interaction has occurred
      window.removeEventListener('pointermove', handleInteractionEvent)
      resolve(true)
    }

    // Listen for the pointerdown event
    window.addEventListener('pointermove', handleInteractionEvent)
  })
}

function visible({ element, margin = 300 }) {
  return new Promise(function (resolve) {
    const observer = new window.IntersectionObserver(
      async function (entries) {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            observer.disconnect()
            resolve(true)
            break
          }
        }
      },
      {
        rootMargin: `${margin}px`,
        // The rootMargin property is set using the margin parameter.
      }
    )
    observer.observe(element)
  })
}

function idle() {
  return new Promise(function (resolve) {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(resolve)
    } else {
      setTimeout(resolve, 200)
    }
  })
}

export const islands = import.meta.glob('@/islands/*.js')

export function revive(islands) {
  const observer = new window.MutationObserver((mutations) => {
    for (let i = 0; i < mutations.length; i++) {
      const { addedNodes } = mutations[i]
      for (let j = 0; j < addedNodes.length; j++) {
        const node = addedNodes[j]
        if (node.nodeType === 1) dfs(node)
      }
    }
  })

  async function dfs(node) {
    const tagName = node.tagName.toLowerCase()
    const potentialJsPath = `/frontend/islands/${tagName}.js`
    const isPotentialCustomElementName = /-/.test(tagName)

    if (isPotentialCustomElementName && islands[potentialJsPath]) {
      if (node.hasAttribute('client:visible')) {
        await visible({ element: node })
      }

      if (node.hasAttribute('client:interaction')) {
        await interaction()
      }

      const clientMedia = node.getAttribute('client:media')
      if (clientMedia) {
        // hack for custom scrollbars within media queries that don't get executed
        if (node.querySelector('custom-scrollbar')) {
          dfs(node.querySelector('custom-scrollbar'))
        }

        if (node.querySelector('.swiper-wrapper')) {
          dfs(node.querySelector('.swiper-wrapper'))
        }

        await media({ query: clientMedia })
      }

      if (node.hasAttribute('client:idle')) {
        await idle()
      }

      islands[potentialJsPath]()
    }

    let child = node.firstElementChild

    while (child) {
      dfs(child)
      child = child.nextElementSibling
    }
  }

  dfs(document.body)

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  })
}
