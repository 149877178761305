/**
 * VariantRadios extends the functionality of VariantSelects to manage radio buttons within fieldsets.
 * It updates the selected options based on the checked radio buttons.
 */
class VariantRadios extends window.VariantSelects {
  /**
   * Updates the options array with the values of the checked radio buttons.
   * For each fieldset, it finds the checked radio button and stores its value in the options array.
   */
  updateOptions() {
    // Get all fieldsets within this custom element
    const fieldsets = Array.from(this.querySelectorAll('fieldset'))

    // Ensure that fieldsets exist before proceeding
    if (fieldsets.length === 0) {
      console.warn('No fieldsets found in variant-radios element.')
      return
    }

    // For each fieldset, find the checked radio and get its value
    this.options = fieldsets.map((fieldset) => {
      const checkedRadio = Array.from(fieldset.querySelectorAll('input')).find(
        (radio) => radio.checked
      )

      // Ensure that a radio button is checked before accessing its value
      if (!checkedRadio) {
        console.warn('No radio button is checked in one of the fieldsets.')
        return null
      }

      return checkedRadio.value
    })
  }
}

// Register the custom element 'variant-radios'
window.customElements.define('variant-radios', VariantRadios)
