class ProductStars extends window.HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.onClick)
  }

  onClick() {
    // if reviewsio-product-reviews-widget on page, scroll to it
    const reviewsWidget = document.querySelector(
      'reviewsio-product-reviews-widget'
    )
    if (reviewsWidget) {
      reviewsWidget.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

// Register the custom element 'product-card'
window.customElements.define('product-stars', ProductStars)
