export default class CoreCssSwiper extends window.HTMLElement {
  constructor() {
    super()
    this.dragging = false
    this.startX = 0
    this.pointerXStart = 0
    this.startScrollLeft = 0

    // Bind methods and store them in properties
    this.boundStartSwipe = this.startSwipe.bind(this)
    this.boundEndSwipe = this.endSwipe.bind(this)
    this.boundLeaveSwipe = this.leaveSwipe.bind(this)
    this.boundMoveSwipe = this.moveSwipe.bind(this)
    this.preventClick = this.preventClick.bind(this)
    this.boundOnScroll = this.onScroll.bind(this)
    this.boundStartThumbDrag = this.startThumbDrag.bind(this)
    this.boundMoveThumbDrag = this.moveThumbDrag.bind(this)
    this.boundEndThumbDrag = this.endThumbDrag.bind(this)
    this.boundOnResize = this.debounce(this.onResize.bind(this), 250)
    this.boundScrollEnd = this.onScrollEnd.bind(this)

    // Create bound functions for next and previous buttons
    this.boundMoveToNextSlide = this.moveToNextSlide.bind(this)
    this.boundMoveToPrevSlide = this.moveToPrevSlide.bind(this)

    this.watchProducts = this.dataset.watchProducts === 'true'

    this.externalButtons = this.dataset.externalButtons

    this.scrollLeftVal = 0

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.initOnView()
            this.observer.disconnect()
          }
        })
      },
      { threshold: 0.1 }
    ) // Adjust the threshold as needed

    this.hasRemovedLazyload = false
  }

  connectedCallback() {
    if (this.matchesMediaQuery()) {
      this.initSwiper()
    } else {
      window.addEventListener('resize', this.boundOnResize)
    }
  }

  initSwiper() {
    this.observer.observe(this)
    // set all images and <a> links for draggable to false
    this.querySelectorAll('img, a').forEach((el) => {
      el.draggable = false
    })
  }

  matchesMediaQuery() {
    const mediaQuery = this.getAttribute('data-media')

    // Return true if no data-media attribute is present (assume all media queries)
    if (!mediaQuery) {
      return true
    }

    return !mediaQuery || window.matchMedia(mediaQuery).matches
  }

  updateDesktopThumbs(index) {
    if (this.querySelector('[is="desktop-thumbs"]')) {
      this.querySelector('[is="desktop-thumbs"]').updateVisuals(index)
    }
  }

  setActiveSlide() {
    // calculate the active slide based on the scroll position
    window.requestAnimationFrame(() => {
      const scrollLeft = this.swiper.scrollLeft
      const activeSlideIdx = Math.round(scrollLeft / this.slideWidth)
      const activeSlide = this.slides[activeSlideIdx]
      const nextSlide = this.slides[activeSlideIdx + 1] || null

      // get pct until next slide

      const pctUntilNextSlide = Math.min(
        (scrollLeft % this.slideWidth) / this.slideWidth,
        0.7
      )

      this.style.setProperty('--pct-until-next-slide', `${pctUntilNextSlide}`)

      this.slides.forEach((slide) => {
        if (slide === nextSlide) {
          if (nextSlide.classList.contains('next-slide') === false) {
            nextSlide.classList.add('next-slide')
          }
        } else {
          slide.classList.remove('next-slide')
        }

        if (slide === activeSlide) {
          slide.classList.add('active')
        } else {
          slide.classList.remove('active')
        }
      })
    })
  }

  initOnView() {
    // Methods previously in connectedCallback
    this.initSwiperElements()
    this.attachListeners()
    this.updateThumbSize()
    this.setActiveSlide()
    this.updateButtonStates()
  }

  disconnectedCallback() {
    this.removeListeners()
  }

  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  onScroll() {
    // send event to other classes
    this.dispatchEvent(new CustomEvent('swiper-scroll', { bubbles: true }))

    if (this.hasRemovedLazyload === false) {
      this.slides.forEach((slide) => {
        slide.querySelectorAll('img').forEach((img) => {
          img.removeAttribute('loading')
          img.removeAttribute('data-src')
          img.removeAttribute('data-srcset')
          img.setAttribute('priority', 'high')
        })
      })
      this.hasRemovedLazyload = true
    }

    this.updateThumbPosition()
    this.updateButtonStates()
    this.setActiveSlide()
    this.setIsSwiping(true)

    this.updateDots()
  }

  onScrollEnd() {
    this.setIsSwiping(false)
    const activeSlideIdx = Math.round(this.swiper.scrollLeft / this.slideWidth)
    this.updateDesktopThumbs(activeSlideIdx)
  }

  updateThumbPosition() {
    if (!this.track || !this.thumb) return // Exit if track or thumb is not present
    const scrollLeft = this.swiper.scrollLeft
    const scrollWidth = this.swiper.scrollWidth
    const clientWidth = this.swiper.clientWidth
    const thumbWidth = this.thumb.clientWidth
    const trackWidth = this.track.clientWidth
    const maxScrollLeft = scrollWidth - clientWidth
    const thumbLeft = (scrollLeft / maxScrollLeft) * (trackWidth - thumbWidth)
    this.thumb.style.transform = `translateX(${thumbLeft}px)`
  }

  initSwiperElements() {
    this.track = this.querySelector('[data-track]')
    this.thumb = this.querySelector('[data-thumb]')
    this.slides = Array.from(this.querySelectorAll('[data-carousel-item]'))
    this.swiper = this.querySelector('[data-carousel]')
    this.updateDimensions()

    if (this.externalButtons) {
      this.nextButtons = Array.from(
        document
          .getElementById(this.externalButtons)
          .querySelectorAll('[data-swiper-next]')
      )
      this.prevButtons = Array.from(
        document
          .getElementById(this.externalButtons)
          .querySelectorAll('[data-swiper-prev]')
      )
    } else {
      this.nextButtons = Array.from(this.querySelectorAll('[data-swiper-next]'))
      this.prevButtons = Array.from(this.querySelectorAll('[data-swiper-prev]'))
    }

    this.dotsElements = Array.from(this.querySelectorAll('[data-dots-wrapper]'))
    this.dots = Array.from(this.querySelectorAll('[data-dot]')) // Assuming each dot has a data-dot attribute
    this.attachDotListeners()
  }

  attachDotListeners() {
    this.dots.forEach((dot, index) => {
      dot.addEventListener('click', () => this.moveToSlide(index))
    })
  }

  moveToSlide(index) {
    const targetPosition = this.slideWidth * index

    this.swiper.scrollTo({
      left: targetPosition,
      behavior: 'smooth',
    })

    // Update state after moving
    // this.updateButtonStates()
    // this.updateDotsActiveState(index)
  }

  updateDotsActiveState(activeIndex) {
    this.dots.forEach((dot, index) => {
      if (index === activeIndex) {
        dot.classList.add('active')
      } else {
        dot.classList.remove('active')
      }
    })
  }

  get slideWidth() {
    return this.slides[0]?.offsetWidth || 0
  }

  updateButtonStates() {
    const atStart = this.swiper.scrollLeft === 0
    const atEnd =
      this.swiper.scrollLeft + this.swiper.offsetWidth >=
      this.swiper.scrollWidth

    this.prevButtons.forEach((button) => (button.disabled = atStart))
    this.nextButtons.forEach((button) => (button.disabled = atEnd))
  }

  moveToNextSlide() {
    const newScrollPosition = this.swiper.scrollLeft + this.slideWidth
    const maxScrollPosition = this.swiper.scrollWidth - this.swiper.clientWidth

    const remainingScrollDistance =
      maxScrollPosition - this.swiper.scrollLeft - this.slideWidth

    const buffer = remainingScrollDistance < 50 ? 50 : 0

    // Use scrollTo with smooth behavior
    this.swiper.scrollTo({
      left: newScrollPosition + buffer,
      behavior: 'smooth',
    })
    this.updateButtonStates()
  }

  moveToPrevSlide() {
    const newScrollPosition = this.swiper.scrollLeft - this.slideWidth

    // Use scrollTo with smooth behavior
    this.swiper.scrollTo({
      left: Math.max(newScrollPosition, 0),
      behavior: 'smooth',
    })

    this.updateButtonStates()
  }

  updateDimensions() {
    this.swiperWidth = this.swiper.offsetWidth
    this.swiperScrollWidth = this.swiper.scrollWidth
    this.updateThumbSize()
  }

  updateDots() {
    if (!this.dotsElements.length) return

    const activeIndex = Math.round(this.swiper.scrollLeft / this.slideWidth)

    this.dotsElements.forEach((dots) => {
      dots.querySelectorAll('span').forEach((dot, i) => {
        if (i === activeIndex) {
          dot.classList.add('active')
        } else {
          dot.classList.remove('active')
        }
      })
    })
    this.updateDotsActiveState(activeIndex)
  }

  updateThumbSize() {
    if (!this.track || !this.thumb) return // Exit if track or thumb is not present

    const visibleRatio = this.track.offsetWidth / this.swiperScrollWidth
    const thumbWidth = visibleRatio * this.track.offsetWidth
    this.thumb.style.width = `${thumbWidth}px`
  }

  setIsSwiping(state) {
    this.classList.toggle('is-swiping', state)
  }

  startSwipe(e) {
    this.isDown = true
    this.swiper.classList.add('active')
    this.startX = e.pageX - this.swiper.offsetLeft
    this.scrollLeftVal = this.swiper.scrollLeft
    this.pointerXStart = e.clientX
  }

  endSwipe(e) {
    this.isDown = false
    this.swiper.classList.remove('active')
    const elements = this.querySelectorAll('a')
    if (this.isDragged) {
      elements.forEach((el) => el.addEventListener('click', this.preventClick))
    } else {
      elements.forEach((el) =>
        el.removeEventListener('click', this.preventClick)
      )
    }
    this.isDragged = false
  }

  leaveSwipe() {
    this.isDown = false
    this.swiper.classList.remove('active')
  }

  moveSwipe(e) {
    if (!this.isDown) return
    this.isDragged = true
    e.preventDefault()
    const x = e.pageX - this.swiper.offsetLeft
    const walk = (x - this.startX) * 2
    this.swiper.scrollLeft = this.scrollLeftVal - walk
  }

  preventClick(e) {
    // Check if this.pointerXStart is not null
    if (this.pointerXStart !== null) {
      // Calculate the absolute difference between e.clientX and this.pointerXStart
      const diff = Math.abs(e.clientX - this.pointerXStart)

      // If the difference is 20 or more, prevent the click event
      if (diff >= 20) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
    } else {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }

  startThumbDrag(e) {
    this.isThumbDown = true
    this.swiper.classList.add('active')
    this.startX = e.pageX - this.swiper.offsetLeft
    this.scrollLeftVal = this.swiper.scrollLeft
  }

  moveThumbDrag(e) {
    if (!this.isThumbDown) return
    e.preventDefault()

    const x = e.pageX
    const deltaX = x - this.startX // Change in mouse position
    const scrollFactor = this.swiperScrollWidth / this.track.clientWidth // Relation between swiper scroll width and track width

    // Calculate new scroll position
    const newScrollPosition = this.scrollLeftVal + deltaX * scrollFactor
    this.swiper.scrollLeft = newScrollPosition
  }

  endThumbDrag() {
    this.isThumbDown = false
    this.swiper.classList.remove('active')
  }

  onResize() {
    if (this.matchesMediaQuery()) {
      if (!this.isInitialized) {
        this.initSwiper()
        this.isInitialized = true
      }
    }
    if (this.matchesMediaQuery() && this.swiper) {
      this.updateDimensions()
      this.updateThumbSize()
      this.updateButtonStates() // if they are dependent on size
      this.updateThumbPosition()
    }
  }

  attachListeners() {
    // this.swiper.addEventListener('scroll', this.boundHandleScroll)
    if (!this.hasAttribute('disabled-click')) {
      this.swiper.addEventListener('mousedown', this.boundStartSwipe)
      this.swiper.addEventListener('mouseleave', this.boundLeaveSwipe)
    }
    this.swiper.addEventListener('mouseup', this.boundEndSwipe)
    this.swiper.addEventListener('mousemove', this.boundMoveSwipe)
    this.swiper.addEventListener('scroll', this.boundOnScroll)
    this.swiper.addEventListener('scrollend', this.boundScrollEnd)
    if (this.thumb) {
      this.thumb.addEventListener('mousedown', this.boundStartThumbDrag)
    }
    this.nextButtons.forEach((button) =>
      button.addEventListener('click', this.boundMoveToNextSlide)
    )
    this.prevButtons.forEach((button) =>
      button.addEventListener('click', this.boundMoveToPrevSlide)
    )
    window.addEventListener('mousemove', this.boundMoveThumbDrag)
    window.addEventListener('mouseup', this.boundEndThumbDrag)
    window.addEventListener('resize', this.boundOnResize)
  }

  removeListeners() {
    window.removeEventListener('resize', this.boundOnResize)
    // this.swiper.removeEventListener('scroll', this.boundHandleScroll)

    if (this.swiper) {
      if (!this.hasAttribute('disabled-click')) {
        this.swiper.removeEventListener('mousedown', this.boundStartSwipe)
        this.swiper.removeEventListener('mouseleave', this.boundLeaveSwipe)
      }
      this.swiper.removeEventListener('mouseup', this.boundEndSwipe)
      this.swiper.removeEventListener('mousemove', this.boundMoveSwipe)
      this.swiper.removeEventListener('scroll', this.boundOnScroll)
      this.swiper.removeEventListener('scrollend', this.boundScrollEnd)
    }
    if (this.thumb) {
      this.thumb.removeEventListener('mousedown', this.boundStartThumbDrag)
    }

    if (this.nextButtons) {
      this.nextButtons.forEach((button) =>
        button.removeEventListener('click', this.boundMoveToNextSlide)
      )
      this.prevButtons.forEach((button) =>
        button.removeEventListener('click', this.boundMoveToPrevSlide)
      )
    }

    window.removeEventListener('mousemove', this.boundMoveThumbDrag)
    window.removeEventListener('mouseup', this.boundEndThumbDrag)
    window.removeEventListener('resize', this.boundOnResize)
  }
}

window.customElements.define('core-css-swiper', CoreCssSwiper)
