import '@ungap/custom-elements'
import 'vite/modulepreload-polyfill'
import { initDisclosureWidgets } from '@/lib/a11y'
import '../core'
import '../merchant'
import { revive, islands } from '@/lib/revive.js'
// import 'web-animations-js'
// import { datadogRum } from '@datadog/browser-rum'

const summaries = document.querySelectorAll('[id^="Details-"] summary')

revive(islands)
initDisclosureWidgets(summaries)

console.group(
  '%cBest in class eComm by 10SQ',
  '\n    background: linear-gradient(140deg, rgba(189,249,90,1) 0%, rgba(189,249,90,0.5) 50%, rgba(189,249,90,1) 100%);\n    padding: 6px 8px;\n    font-size: 14px;\n    border-radius: 4px;\n    color: #000000;\n  '
)
console.log(
  '\n%c🖤 Shopify & Shopify Plus\n\n✅ Design + Development\n\n📍 Melbourne, Australia\n\n\nLearn more at: https://10sq.dev\n',
  '\n    font-size: 14px;\n    line-height: 1.5;\n    margin: -20px 0;\n  '
)
console.groupEnd()

/* taget="_blank" external links */

document.addEventListener('DOMContentLoaded', () => {
  // Select all anchor tags
  const links = document.querySelectorAll('a[href^="http"]')

  links.forEach((link) => {
    // Check if the link is external
    if (link.hostname !== window.location.hostname) {
      link.target = '_blank'
      link.rel = 'noopener'
    }
  })
})

/*
datadogRum.init({
  applicationId: '7d1b974f-ca69-4c85-9948-2f6482494e51',
  clientToken: 'pub71c660bda3c8822556d17be43fb0fd5e',
  site: 'datadoghq.com',
  service: 'vitaco---msh',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 10,
  sessionReplaySampleRate: 10,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

if (window.DD_ID != null) {

  datadogRum.setUser({
    id: window.DD_ID,
    email: window.DD_EMAIL,
    name: window.DD_NAME,
  })

}

datadogRum.startSessionReplayRecording()

*/

window.openKlaviyoPopup = (id) => {
  window._klOnsite.push(['openForm', id])
  setTimeout(() => {
    if (document.querySelector(`[data-testid="klaviyo-form-${id}"] input`)) {
      document.querySelector(`[data-testid="klaviyo-form-${id}"] input`).focus()
    }
  }, 200)
}
