const TabControlMixin = (Base) =>
  class extends Base {
    setActiveBlock(target) {
      // remove active class from all tabs
      this.querySelectorAll('[role="tab"]').forEach((tab) => {
        tab.classList.remove('active')
        tab.setAttribute('aria-selected', false)
      })
      // set target tab as active
      target.classList.add('active')
      target.setAttribute('aria-selected', true)

      // remove active class from all panels
      this.querySelectorAll('[role="tabpanel"]').forEach((panel) => {
        panel.classList.remove('active')
        panel.setAttribute('aria-hidden', true)
      })

      // set target panel as active
      const targetPanel = this.querySelector(
        `#${target.getAttribute('aria-controls')}`
      )

      targetPanel.classList.add('active')
      targetPanel.setAttribute('aria-hidden', false)
    }

    setupTabs() {
      const tabs = this.querySelectorAll('[role="tab"]')
      tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
          e.preventDefault()
          this.setActiveBlock(e.target)
        })
      })
    }
  }

window.TabControlMixin = TabControlMixin
