class FacetRemove extends HTMLElement {
  constructor() {
    super()
    this.facetLink = null
  }

  connectedCallback() {
    this.facetLink = this.querySelector('a')
    if (!this.facetLink.getAttribute('role')) {
      this.facetLink.setAttribute('role', 'button')
    }
    this.facetLink.addEventListener('click', this.closeFilter.bind(this))
    this.facetLink.addEventListener('keyup', this.handleKeyUp.bind(this))
  }

  disconnectedCallback() {
    this.facetLink.removeEventListener('click', this.closeFilter.bind(this))
    this.facetLink.removeEventListener('keyup', this.handleKeyUp.bind(this))
  }

  handleKeyUp(event) {
    event.preventDefault()
    if (event.code.toUpperCase() === 'SPACE') {
      this.closeFilter(event)
    }
  }

  closeFilter(event) {
    event.preventDefault()
    const form =
      this.closest('facet-filters-form') ||
      document.querySelector('facet-filters-form')
    if (!form) {
      console.error('No form element found')
      return
    }
    if (typeof form.onActiveFilterClick !== 'function') {
      console.error('form.onActiveFilterClick is not a function')
      return
    }
    form.onActiveFilterClick(event)
  }
}

if (!customElements.get('facet-remove')) {
  customElements.define('facet-remove', FacetRemove)
}
