import { removeTrapFocus, trapFocus } from '@/lib/a11y'
import DetailsModal from './details-modal'

/**
 * HeaderDrawer class represents a custom web component that extends DetailsModal.
 * It provides methods for opening and closing a header drawer with accessibility considerations.
 */
class HeaderDrawer extends DetailsModal {
  /**
   * Opens the header drawer.
   * @param {Event} event - The event that triggered the opening action.
   */
  open(event) {
    setTimeout(() => {
      this.detailsContainer.classList.add('menu-opening')
    })
    this.onBodyClickEvent = this.onBodyClickEvent || this.onBodyClick.bind(this)
    event.target.closest('details').setAttribute('open', true)
    document.body.addEventListener('click', this.onBodyClickEvent)
    document.body.classList.add('overflow-hidden', 'lg:overflow-auto')

    trapFocus(this.detailsContainer.querySelector('[tabindex="-1"]'))

    // Remove lazy loading on images
    const images = this.detailsContainer.querySelectorAll('img')

    images.forEach((image) => {
      image.removeAttribute('loading')
    })
  }

  /**
   * Closes the header drawer.
   * @param {boolean} focusToggle - Whether to toggle focus back after closing.
   */
  close(focusToggle = true) {
    removeTrapFocus(focusToggle ? this.summaryToggle : null)
    document.body.removeEventListener('click', this.onBodyClickEvent)
    this.detailsContainer.classList.remove('menu-opening')
    document.body.classList.remove('overflow-hidden', 'lg:overflow-auto')
    this.closeAnimation()
  }

  /**
   * Callback triggered when the component is connected to the DOM.
   * Adds a CSS class to indicate hydration status and applies menu-opening class if 'open' attribute exists.
   */
  connectedCallback() {
    this.classList.add('is-hydrated')

    if (this.detailsContainer.hasAttribute('open')) {
      this.detailsContainer.classList.add('menu-opening')
    }
  }

  /**
   * Initiates the animation for closing the header drawer.
   */
  closeAnimation() {
    let animationStart

    const handleAnimation = (time) => {
      if (animationStart === undefined) {
        animationStart = time
      }

      this.detailsContainer.removeAttribute('open')
    }

    window.requestAnimationFrame(handleAnimation)
  }
}

// Define the 'header-drawer' custom element using the HeaderDrawer class.
window.customElements.define('header-drawer', HeaderDrawer)
