import tippy from 'tippy.js'

class TooltipWrapper extends window.HTMLElement {
  connectedCallback() {
    const button = this.querySelector('button')
    tippy(button, {
      placement: 'top',
      interactive: true,
      content: `<span>You can only complete this action once you have been invited to.</span>`,
      allowHTML: true,
    })
  }
}

window.customElements.define('rewards-tooltip-wrapper', TooltipWrapper)
