/**
 * FilterDrawer class represents a custom web component that extends the Drawer class.
 * It provides methods for opening a filter drawer and loading its content lazily.
 */
class FilterDrawer extends window.Drawer {
  /**
   * Flag to track whether the drawer content has been loaded.
   */
  isLoaded = false

  /**
   * Opens the filter drawer and loads its content if not already loaded.
   */

  async open() {
    super.open() // Call the open method from the parent Drawer class.

    // Load content lazily if not already loaded.
    if (!this.isLoaded) {
      const template = this.querySelector('template') // Assuming the <template> is a direct child of the FilterDrawer.
      if (template) {
        const content = template.content.cloneNode(true)
        this.appendChild(content)
      }
      this.isLoaded = true // Mark content as loaded to prevent re-loading.
    }
  }
}

// Define the 'filter-drawer' custom element using the FilterDrawer class.
window.customElements.define('filter-drawer', FilterDrawer)
