if (window.pageTabTitleMessage) {
  // Store the original title
  const originalTitle = document.title

  // Variable to store the interval ID
  let intervalID = null

  // Function to handle the visibility change
  function handleVisibilityChange() {
    if (document.hidden) {
      // Page is not visible
      // Clear any existing interval to avoid duplicates
      clearInterval(intervalID)

      // Set the interval to alternate the title every 2 seconds
      intervalID = setInterval(() => {
        // Check if the current title is the original
        if (document.title === originalTitle) {
          document.title = window.pageTabTitleMessage
        } else {
          document.title = originalTitle
        }
      }, 2000)
    } else {
      // Page is visible again
      // Clear the interval and reset the title
      clearInterval(intervalID)
      document.title = originalTitle
    }
  }

  // Listen for visibility change events
  document.addEventListener('visibilitychange', handleVisibilityChange)
}
