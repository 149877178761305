import tippy from 'tippy.js'

class TooltipWrapper extends window.HTMLElement {
  connectedCallback() {
    const button = this.querySelector('button')
    tippy(button, {
      placement: 'top',
      interactive: true,
      content: `${this.getAttribute('data-content')}`,
      allowHTML: true,
    })
  }
}

window.customElements.define('tooltip-wrapper', TooltipWrapper)
