class Modal extends window.HTMLElement {
  parent = this.parentNode

  constructor() {
    super()
    this.open = this.open.bind(this)
  }

  disconnectedCallback() {
    const trigger = document.querySelector(`[aria-controls="${this.id}"]`)

    if (trigger) {
      trigger.removeEventListener('click', this.open)
    }
  }

  open() {
    this.dispatchEvent(new window.CustomEvent('modal:open'))

    if (this.shouldLock) {
      document.body.classList.add('overflow-hidden')
    }
    if (this.shouldTrapFocus) {
      this.trapFocus(this)
    }

    if (this.shouldAppendToBody) {
      // move the modal to the end of the body
      document.body.appendChild(this)
    }

    // Use requestAnimationFrame to trigger the transition

    this.setAttribute('open', true)

    const shadowRootContent = this.shadowRoot.querySelector('.modal-content')

    requestAnimationFrame(() => {
      shadowRootContent.style.opacity = 1
    })
  }

  trapFocus(container, focusElement) {
    const focusableElements = container.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    )
    const firstFocusableElement =
      focusElement ||
      focusableElements[0] ||
      this.shadowRoot?.querySelector('slot') ||
      undefined

    if (!firstFocusableElement) return

    firstFocusableElement.focus()
  }

  updatePositionFromAttributes() {
    const justifyContentMobile =
      this.getAttribute('data-mobile-justify-content') || 'center'
    const alignItemsMobile =
      this.getAttribute('data-mobile-align-items') || 'start'

    const justifyContentDesktop =
      this.getAttribute('data-desktop-justify-content') || 'center'
    const alignItemsDesktop =
      this.getAttribute('data-desktop-align-items') || 'center'

    const modalContent = this.shadowRoot.querySelector('.modal-content')

    if (window.innerWidth >= 1024) {
      modalContent.style.justifyContent = justifyContentDesktop
      modalContent.style.alignItems = alignItemsDesktop
    } else {
      modalContent.style.justifyContent = justifyContentMobile
      modalContent.style.alignItems = alignItemsMobile
    }
  }

  addMediaQueryListener() {
    const mediaQuery = window.matchMedia('(min-width: 1024px)')
    mediaQuery.addListener((e) => {
      this.updatePositionFromAttributes()
    })
  }

  close() {
    const shadowRootContent = this.shadowRoot.querySelector('.modal-content')
    requestAnimationFrame(() => {
      shadowRootContent.style.opacity = 0
    })
    this.dispatchEvent(new window.CustomEvent('modal:close'))
    if (this.shouldLock) {
      document.body.classList.remove('overflow-hidden')
    }

    if (this.shouldAppendToBody) {
      // move the modal back to its original position
      this.parent.appendChild(this)
    }

    this.removeAttribute('open')

    document.dispatchEvent(new window.CustomEvent('theme:loading:cancel'), {
      bubbles: true,
    })
  }

  get shouldLock() {
    return true
  }

  get shouldTrapFocus() {
    return true
  }

  get shouldAppendToBody() {
    return true
  }

  connectedCallback() {
    const trigger = document.querySelector(`[aria-controls="${this.id}"]`)

    if (trigger) {
      trigger.addEventListener('click', this.open)
    }

    document.addEventListener('keyup', (evt) => {
      if (evt.code === 'Escape') {
        this.close()
      }
    })

    this.setAttribute('aria-modal', 'true')
    this.setAttribute('role', 'dialog')
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' })
      this.render()
    }
    this.updatePositionFromAttributes()
    this.addMediaQueryListener()
  }

  render() {
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: none;
          position: fixed;
          z-index: 9999999;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          overflow: auto;
          opacity: 0; /* Initial opacity set to 0 */
          visibility: hidden; /* Initial visibility set to hidden */
          backdrop-filter: blur(0px); 
          -webkit-backdrop-filter: blur(0px);
          transition: opacity 300ms ease-in-out, backdrop-filter 300ms ease-in-out, -webkit-backdrop-filter 300ms ease-in-out;
          pointer-events: none;
        }

        .modal-content{
            display: flex;
            justify-content: center;
            align-items: start;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        @media (min-width: 1024px) {
          .modal-content{
            align-items: center;
          }
        }

        .modal-background{
            background-color: rgba(0,0,0,0.4);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index:-1;
        }

        :host([open]) {
            display: block !important;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
        }

        button{

            position: absolute;
            top: 18px;
            right: 4px;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            z-index: 1;
            
        }

        @media (min-width: 1024px) {
          button{
              top: 1rem;
              right: 1rem;
          }
        }

        .slot-wrapper{
          position: relative;
        }


      </style>
      <div class="modal-content">
        <div class="modal-background" aria-hidden="true"></div>
        <div class='slot-wrapper'>
        <button>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L5 16" stroke="white" stroke-width="6" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M5 5L15 16" stroke="white" stroke-width="6" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
        </button>
        <slot></slot>
        </div>
      </div>
    `

    const shadowRootContent = this.shadowRoot.querySelector('.modal-content')
    const shadowClose = this.shadowRoot.querySelector('.slot-wrapper button')

    shadowRootContent.addEventListener('click', (evt) => {
      if (evt.target === shadowRootContent) {
        this.close()
      }
    })
    shadowClose.addEventListener('click', (evt) => {
      this.close()
    })
  }
}

window.Modal = Modal
