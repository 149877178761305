class ForYouModule {
  constructor() {
    this.state = JSON.parse(
      document.querySelector('[data-for-you-initial-state]').textContent
    )
    this.boundWriteOrderProperty = this.writeOrderProperty.bind(this)
    this.hasChanges = false
    this.parseUrlParams() // Call this method to initialize the state from URL parameters
    this.init()
  }

  parseUrlParams() {
    const params = new URLSearchParams(window.location.search)
    // Assuming the parameter keys for 'toAdd' start with 'fy_' prefix
    params.forEach((value, key) => {
      if (key.startsWith('fy_')) {
        const stateKey = key.substring(3) // Remove 'fy_' prefix to match the state keys
        if (!this.state.toAdd) {
          this.state.toAdd = {} // Initialize the 'toAdd' object if it doesn't exist
        }
        // Split the value by ',' and assign it to the corresponding key in 'toAdd'
        this.state.toAdd[stateKey] = value
      }
    })
  }

  writeField(storeKey, addKey) {
    if (this.state.toAdd[addKey]) {
      // Modify this to support an array of values from URL parameters
      let stateArray = this.state[storeKey]
        ? this.state[storeKey].split(',')
        : []
      const toAdds = Array.isArray(this.state.toAdd[addKey])
        ? this.state.toAdd[addKey]
        : [this.state.toAdd[addKey]]

      toAdds.forEach((toAdd) => {
        // Remove the addKey if it already exists to prevent duplicates
        stateArray = stateArray.filter((item) => item !== toAdd)
        // Prepend the addKey to the start of the array
        stateArray.unshift(toAdd)
      })

      // Limit the array to the latest 5 values
      stateArray = stateArray.slice(0, 5)
      // Update the state with the new, joined string
      this.state[storeKey] = stateArray.join(',')
      this.hasChanges = true
    }
  }

  writeOrderProperty() {
    const formData = new FormData()

    formData.append('attributes[_VIEWED_COLLECTIONS]', this.state.collections)
    formData.append('attributes[_VIEWED_PRODUCTS]', this.state.products)
    formData.append('attributes[_TOPICS]', this.state.topics)
    formData.append('attributes[_ATHLETES]', this.state.athletes)

    fetch(window.Shopify.routes.root + 'cart/update.js', {
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
  }

  writeToAdd() {
    this.writeField('products', 'product')
    this.writeField('collections', 'collection')
    this.writeField('topics', 'topic')
    this.writeField('athletes', 'athlete')
  }

  processChanges() {
    if (this.hasChanges) {
      this.writeOrderProperty()
    }
  }

  init() {
    this.writeToAdd()
    this.processChanges()
  }
}

const forYouModule = new ForYouModule()

window.forYouModule = forYouModule
