/**
 * CustomAccordion class represents a custom web component that enhances the functionality of <details> elements for creating an accordion.
 */
class CustomAccordion extends HTMLElement {
  constructor() {
    super()

    // Initialize variables to manage animations and state
    this.animation = null
    this.isClosing = false
    this.isExpanding = false

    // Bind the onClick method to this instance
    this.boundOnClick = this.onClick.bind(this)
  }

  /**
   * Callback triggered when the component is connected to the DOM.
   * Initializes references to relevant elements and adds event listeners.
   */
  connectedCallback() {
    this.detailsElement = this.querySelector('details')
    this.summary = this.detailsElement.querySelector('summary')
    this.content = this.detailsElement.querySelector('.content')

    // If the details element is initially open, add the open class
    if (this.detailsElement.hasAttribute('open')) {
      this.detailsElement.classList.add('open')
    }

    // Add a CSS class for hydrated state
    this.classList.add('is-hydrated')

    // Listen for user clicks on the summary element
    this.summary.addEventListener('click', this.boundOnClick)
  }

  /**
   * Callback triggered when the component is disconnected from the DOM.
   * Removes event listeners to prevent memory leaks.
   */
  disconnectedCallback() {
    this.summary.removeEventListener('click', this.boundOnClick)
  }

  /**
   * Handles user clicks on the summary element, triggering accordion behavior.
   * @param {Event} e - The click event on the summary element.
   */
  onClick(e) {
    e.preventDefault()

    // Toggle between expanding and shrinking based on current state
    if (this.isClosing || !this.detailsElement.open) {
      this.open()
    } else if (this.isExpanding || this.detailsElement.open) {
      this.shrink()
    }
  }

  /**
   * Shrinks the accordion by animating the height and updating classes.
   */
  shrink() {
    this.isClosing = true
    const startHeight = `${this.detailsElement.offsetHeight}px`

    // Remove the 'open' class from details element
    this.detailsElement.classList.remove('open')

    const endHeight = `${this.summary.offsetHeight}px`

    if (this.animation) {
      this.animation.cancel()
    }

    this.animation = this.detailsElement.animate(
      { height: [startHeight, endHeight] },
      { duration: 300, easing: 'ease-out' }
    )

    this.animation.onfinish = () => this.onAnimationFinish(false)
    this.animation.oncancel = () => (this.isClosing = false)
  }

  /**
   * Expands the accordion by animating the height and updating classes.
   */
  open() {
    this.detailsElement.style.height = `${this.detailsElement.offsetHeight}px`
    this.detailsElement.classList.add('open')
    this.detailsElement.open = true

    // Delay the expand action to next frame
    window.requestAnimationFrame(() => this.expand())
  }

  /**
   * Continues the expand animation, adjusting the height and updating classes.
   */
  expand() {
    this.isExpanding = true
    const startHeight = `${this.detailsElement.offsetHeight}px`

    const endHeight = `${
      this.summary.offsetHeight + this.content.offsetHeight
    }px`

    if (this.animation) {
      this.animation.cancel()
    }

    this.animation = this.detailsElement.animate(
      { height: [startHeight, endHeight] },
      { duration: 300, easing: 'ease-out' }
    )

    this.animation.onfinish = () => this.onAnimationFinish(true)
    this.animation.oncancel = () => (this.isExpanding = false)
  }

  /**
   * Callback triggered when the animation finishes.
   * Updates the state and removes animation-related styles.
   * @param {boolean} open - Whether the details element should be open.
   */
  onAnimationFinish(open) {
    this.detailsElement.open = open
    this.animation = null
    this.isClosing = false
    this.isExpanding = false
    this.detailsElement.style.height = ''
  }
}

// Define the custom element 'accordion-wrapper' using the CustomAccordion class.
customElements.define('accordion-wrapper', CustomAccordion)
