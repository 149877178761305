/**
 * QuickBuyModal extends the functionality of the Modal class to provide a quick buy modal.
 * When opened, it fetches product details and displays them in the modal.
 * The content is fetched only once, and subsequent open actions display the cached content.
 */
class QuickBuyModal extends window.Modal {
  isLoaded = false

  /**
   * Opens the modal. If the modal content isn't loaded, it fetches the content and then displays it.
   * @param {Event} e - The event that triggered the open action.
   * @param {string} handle - The handle of the product to fetch. Usually null, since the handle is set as an attribute.
   */

  async close(e) {
    // Call the close method of the parent Modal class
    super.close()

    this.isLoaded = false
    this.replaceChildren(null)
  }

  async open(e, targetHandle = undefined) {
    // If the modal content is already loaded, don't fetch it again. However, if a handle is provided, then fetch the content again.

    if (targetHandle !== undefined) {
      this.isLoaded = false
    }

    if (!this.isLoaded) {
      // Notify that loading has started
      document.dispatchEvent(
        new CustomEvent('theme:loading:start', {
          bubbles: true,
        })
      )

      const handle = targetHandle || this.getAttribute('handle')

      try {
        const response = await fetch(
          `${window.Shopify.routes.root}products/${handle}`
        )
        const responseContent = await response.text()

        // Parse the fetched content
        const responseDoc = new DOMParser().parseFromString(
          responseContent,
          'text/html'
        )
        const quickBuyContent =
          responseDoc.getElementById('quick-buy-content').content

        // Remove all <noscript> tags from the content
        Array.from(quickBuyContent.querySelectorAll('noscript')).forEach(
          (noScript) => noScript.remove()
        )

        // Replace the modal's children with the fetched content
        this.replaceChildren(document.importNode(quickBuyContent, true))

        // Initialize Shopify payment button if available
        window.Shopify?.PaymentButton?.init()
        this.isLoaded = true
      } catch (error) {
        console.error('Failed to load quick buy modal content:', error)
      } finally {
        // Notify that loading has ended
        document.dispatchEvent(
          new CustomEvent('theme:loading:end', {
            bubbles: true,
          })
        )
      }
    }

    // Call the open method of the parent Modal class
    super.open()
  }
}

// Register the custom element 'quick-buy-modal'
window.customElements.define('quick-buy-modal', QuickBuyModal)
